import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useUserState } from 'contexts/userContext';
import { color, font, mobileSize } from 'styles/variables';
import UserMenu from 'components/UserMenu';
import GlobalMenu from 'components/GlobalMenu';
import { logo } from 'assets';

const S = {
  Header: styled.header`
    padding: 20px 30px;
    background-color: ${color.white};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2000;
    ${mobileSize} {
      padding: 1rem;
    }
  `,
  Spacing: styled.div`
    height: 86px;
    ${mobileSize} {
      height: 60px;
    }
  `,
  Container: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  Nav: styled.nav`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    /* ${mobileSize} {
      display: none;
    } */
  `,
  Logo: styled.img`
    height: 100%;
  `,
  NavItem: styled.span`
    font-size: ${font.button};
    margin-left: 2rem;
    color: ${color.gray07};
  `,
  Link: styled(Link)`
    height: 2.875rem;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
      opacity: 0.5;
    }
    ${mobileSize} {
      height: 1.75rem;
    }
  `,
  Toggle: styled.div`
    cursor: pointer;
    height: 2.875rem;
  `,
  Profile: styled.img`
    width: 2.875rem;
    margin-left: 2rem;
    align-self: flex-end;
  `,
  Button: styled.div`
    display: inline-block;
    background-color: ${color.main};
    color: ${color.white};
    padding: 1rem 2rem;
    border-radius: 3rem;
    margin-left: 2rem;
    font-size: ${font.button};
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  `,
};

const Header = () => {
  const { isLogin, profileImage } = useUserState();
  const [toggle, setToggle] = useState(false);
  const onClick = () => {
    setToggle(!toggle);
  };

  return (
    <>
      <S.Header id="header">
        <S.Container>
          <S.Link to="/">
            <S.Logo src={logo} alt="logo" />
          </S.Link>
          <S.Nav>
            <S.NavItem>진로 고민 유형 테스트</S.NavItem>
            {/* <S.Link to="/about">
              <S.NavItem>프로진로고민러 소개</S.NavItem>
            </S.Link>
            <S.Link to="/test">
              <S.NavItem>고민 유형 테스트</S.NavItem>
            </S.Link>
            <S.Link to="/video">
              <S.NavItem>영상 코칭 가이드</S.NavItem>
            </S.Link>
            <S.Link to="/store">
              <S.NavItem>온라인 스토어</S.NavItem>
            </S.Link>
            {isLogin ? (
              <S.Toggle onClick={onClick}>
                <S.Profile src={profileImage} alt="user profile" />
              </S.Toggle>
            ) : (
              <>
                <S.Link to="/login">
                  <S.NavItem>로그인</S.NavItem>
                </S.Link>
                <Link to="/test">
                  <S.Button>진로 고민 시작하기</S.Button>
                </Link>
              </>
            )} */}
          </S.Nav>
        </S.Container>
        <UserMenu toggle={toggle} setToggle={setToggle} />
      </S.Header>
      <S.Spacing />
      {/* <GlobalMenu /> */}
    </>
  );
};

export default Header;
