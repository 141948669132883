import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, font, mobileSize } from 'styles/variables';
import { icon_check_circle } from 'assets';

const S = {
  Container: styled.div`
    padding: 4rem 0;
    border-bottom: 1px solid ${color.gray02};
    ${mobileSize} {
      padding: 2rem 0;
    }
    opacity: ${props => (props.disabled ? '0.3' : '1')};
  `,
  Question: styled.p`
    text-align: center;
    color: ${color.gray07};
    font-size: ${font.question};
    font-weight: 700;
    margin-bottom: 2.5rem;
    line-height: 1.6;
    ${mobileSize} {
      font-size: ${font.button};
      margin-bottom: 1.5rem;
    }
  `,
  InputContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    ${mobileSize} {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  `,
  Text: styled.div`
    color: ${color.gray07};
    margin: 0 1rem;
    ${mobileSize} {
      order: 10;
      margin: 1rem 0.5rem 0 0.5rem;
      font-size: ${font.small};
    }
  `,
  Item: styled.label`
    position: relative;
    ${props => {
      if (props.size === 'large') {
        return `width: 70px; height: 70px; ${mobileSize} {width: 3.5rem; height: 3.5rem}`;
      }
      if (props.size === 'small') {
        return `width: 50px; height: 50px; ${mobileSize} {width: 2.5rem; height: 2.5rem}`;
      }
      return `width: 60px; height: 60px; ${mobileSize} {width: 3rem; height: 3rem}`;
    }}
    height: ${props => {
      if (props.size === 'large') {
        return '70px';
      }
      if (props.size === 'small') {
        return '50px';
      }
      return '60px';
    }};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
    ${mobileSize} {
      margin: 0 0.5rem;
    }
  `,
  HideRadio: styled.input`
    width: 100%;
    height: 100%;
    margin: 0;
    -webkit-appearance: none;
    box-shadow: none;
    display: none;
    &:checked + label {
      background-image: url(${icon_check_circle});
      background-size: cover;
      border: none;
    }
  `,
  Label: styled.label`
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    display: inline-block;
    border-radius: 50%;
    border: 1px solid ${color.gray05};
    box-sizing: border-box;
    cursor: pointer;
  `,
};

const QuestionItem = ({ step, index, question, checkSubmission, disabled }) => {
  const container = useRef();
  const header = document.getElementById('header');

  const handleChange = (...args) => {
    const questionContainer = container.current;
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: questionContainer.offsetTop - header.offsetHeight - 48,
    });
    checkSubmission(...args);
  };

  return (
    <S.Container ref={container} disabled={disabled}>
      <S.Question>{question.content}</S.Question>
      <S.InputContainer>
        <S.Text>전혀 아니다</S.Text>
        <S.Item size="large">
          <S.HideRadio
            type="radio"
            name={question._id}
            id={`${question.title}_answer_1`}
            value="1"
            checked={question.answer === 1}
            onChange={event => handleChange(event, step, index)}
            disabled={disabled}
          />
          <S.Label htmlFor={`${question.title}_answer_1`} />
        </S.Item>
        <S.Item>
          <S.HideRadio
            type="radio"
            name={question._id}
            id={`${question.title}_answer_2`}
            value="2"
            checked={question.answer === 2}
            onChange={event => handleChange(event, step, index)}
            disabled={disabled}
          />
          <S.Label htmlFor={`${question.title}_answer_2`} />
        </S.Item>
        <S.Item size="small">
          <S.HideRadio
            type="radio"
            name={question._id}
            id={`${question.title}_answer_3`}
            value="3"
            checked={question.answer === 3}
            onChange={event => handleChange(event, step, index)}
            disabled={disabled}
          />
          <S.Label htmlFor={`${question.title}_answer_3`} />
        </S.Item>
        <S.Item>
          <S.HideRadio
            type="radio"
            name={question._id}
            id={`${question.title}_answer_4`}
            value="4"
            checked={question.answer === 4}
            onChange={event => handleChange(event, step, index)}
            disabled={disabled}
          />
          <S.Label htmlFor={`${question.title}_answer_4`} />
        </S.Item>
        <S.Item size="large">
          <S.HideRadio
            type="radio"
            name={question._id}
            id={`${question.title}_answer_5`}
            value="5"
            checked={question.answer === 5}
            onChange={event => handleChange(event, step, index)}
            disabled={disabled}
          />
          <S.Label htmlFor={`${question.title}_answer_5`} />
        </S.Item>
        <S.Text>아주 그렇다</S.Text>
      </S.InputContainer>
    </S.Container>
  );
};

QuestionItem.propTypes = {
  step: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  question: PropTypes.shape({
    _id: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    answer: PropTypes.number,
  }).isRequired,
  checkSubmission: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default QuestionItem;
