import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import Button from 'components/Button';

const S = {
  Container: styled.div`
    width: 100%;
    max-width: 380px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 5rem;
  `,
};

const ResultButtons = ({ shareKakaotalk }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push('/test');
  };

  return (
    <S.Container>
      <Button
        width="380px"
        fill="outline"
        onClick={() => shareKakaotalk()}
        text="카카오톡으로 친구에게 공유하기"
      />
      <Button
        style={{ marginTop: '1rem' }}
        width="380px"
        fill="solid"
        onClick={handleClick}
        text="다시하기"
      />
    </S.Container>
  );
};

ResultButtons.propTypes = {
  shareKakaotalk: PropTypes.func.isRequired,
};

export default ResultButtons;
