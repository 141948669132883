import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { color, font } from 'styles/variables';
import Button from 'components/Button';
import VideoItem from './VideoItem';

const S = {
  Container: styled.div`
    width: 100%;
    max-width: 1180px;
    margin: auto;
    padding: 120px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  UserContainer: styled.div`
    width: 100%;
    max-width: 280px;
  `,
  InfoContainer: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.5rem;
  `,
  ProfileImg: styled.img`
    width: 5rem;
    height: 5rem;
  `,
  Info: styled.div`
    margin-left: 1.5rem;
  `,
  UserName: styled.div`
    font-weight: 700;
    color: ${color.gray07};
    margin-bottom: 0.5rem;
  `,
  Email: styled.div`
    font-size: ${font.small};
    color: ${color.gray05};
  `,
  ClassContainer: styled.div`
    margin-top: 2.5rem;
  `,
  Class: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
  `,
  ClassText: styled.div`
    font-size: ${font.button};
    color: ${color.darkgray};
  `,
  VideoContainer: styled.div`
    width: 100%;
    max-width: 780px;
  `,
  VideoSection: styled.div`
    margin-bottom: 3rem;
  `,
  VideoTitle: styled.div`
    font-size: ${font.title};
    color: ${color.darkgray};
    font-weight: 700;
    margin-bottom: 1.5rem;
  `,
  VideoItems: styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  `,
};

const UserProfile = ({
  name,
  profileImage,
  email,
  takingClass,
  completedClass,
}) => {
  return (
    <S.Container>
      <S.UserContainer>
        <S.InfoContainer>
          <S.ProfileImg src={profileImage} alt="user profile" />
          <S.Info>
            <S.UserName>{name}</S.UserName>
            <S.Email>{email}</S.Email>
          </S.Info>
        </S.InfoContainer>
        <Link to="/profile/edit">
          <Button fill="outline" width="100%" text="프로필 편집하기" />
        </Link>
        <S.ClassContainer>
          <S.Class>
            <S.ClassText>수강 중인 클래스</S.ClassText>
            <S.ClassText>{takingClass.length} 개</S.ClassText>
          </S.Class>
          <S.Class>
            <S.ClassText>완료한 클래스</S.ClassText>
            <S.ClassText>{completedClass.length} 개</S.ClassText>
          </S.Class>
        </S.ClassContainer>
      </S.UserContainer>
      <S.VideoContainer>
        <S.VideoSection>
          <S.VideoTitle>수강 중인 클래스</S.VideoTitle>
          <S.VideoItems>
            {takingClass.map(item => (
              <VideoItem
                key={item.pk}
                thumnail={item.thumnail}
                title={item.title}
                step={item.step}
                progress={item.progress}
              />
            ))}
          </S.VideoItems>
        </S.VideoSection>
        <S.VideoSection>
          <S.VideoTitle>완료한 클래스</S.VideoTitle>
          <S.VideoItems>
            {completedClass.map(item => (
              <VideoItem
                key={item.pk}
                thumnail={item.thumnail}
                title={item.title}
                step={item.step}
                progress={item.progress}
              />
            ))}
          </S.VideoItems>
        </S.VideoSection>
      </S.VideoContainer>
    </S.Container>
  );
};

UserProfile.propTypes = {
  name: PropTypes.string.isRequired,
  profileImage: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  takingClass: PropTypes.arrayOf(PropTypes.object).isRequired,
  completedClass: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default UserProfile;
