import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import { color, font } from 'styles/variables';
import { icon_alert, icon_error } from 'assets';

const fadeInOut = keyframes`
  0% {
    transform: translate(-50%, -50%);
    opacity: 0;
  }
  10% {
    transform: translate(-50%, -10%);
    opacity: 1;
  }
  90%{
    transform: translate(-50%, -10%);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const S = {
  Container: styled.div`
    border-radius: 3rem;
    background-color: ${props => (props.isError ? color.red : color.darkgray)};
    color: ${color.white};
    font-size: ${font.button};
    padding: 1rem 5rem;
    width: 100%;
    max-width: 580px;
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -10%);
    z-index: 1001;
    animation: ${fadeInOut} 3s ease-in-out alternate;
    opacity: 0;
  `,
  Icon: styled.img`
    position: absolute;
    left: 2rem;
    top: 50%;
    transform: translateY(-50%);
  `,
};

const Snackbar = ({ text, isError }) => {
  return (
    <S.Container isError={isError}>
      {isError ? (
        <S.Icon src={icon_error} alt="error icon" />
      ) : (
        <S.Icon src={icon_alert} alt="alert icon" />
      )}
      {text}
    </S.Container>
  );
};

Snackbar.propTypes = {
  text: PropTypes.string.isRequired,
  isError: PropTypes.bool,
};

Snackbar.defaultProps = {
  isError: false,
};

export default Snackbar;
