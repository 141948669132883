import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, font } from 'styles/variables';

const S = {
  Item: styled.label`
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  HideRadio: styled.input`
    width: 100%;
    height: 100%;
    margin: 0;
    -webkit-appearance: none;
    box-shadow: none;
    display: none;
    &:checked + label {
      border-color: ${color.darkgray};
      &::after {
        content: '';
        display: inline-block;
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 50%;
        background-color: ${color.darkgray};
      }
    }
  `,
  Label: styled.label`
    display: inline-block;
    width: 1.625rem;
    height: 1.625rem;
    border-radius: 50%;
    border: 2px solid ${color.gray05};
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Text: styled.span`
    color: ${color.gray07};
    font-size: ${font.button};
    margin-left: 1rem;
  `,
};

const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  ...props
}) => {
  return (
    <S.Item>
      <S.HideRadio
        name={name}
        id={id}
        type="radio"
        value={id} // could be something else for output?
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
      <S.Label htmlFor={id} />
      <S.Text>{label}</S.Text>
    </S.Item>
  );
};

RadioButton.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

RadioButton.defaultProps = {
  field: {},
};

export default RadioButton;
