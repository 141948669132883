import React from 'react';
import ReactDOM from 'react-dom';
import App from './features/app/App';
import UserProvider from './contexts/userContext';
import VidoProvider from './contexts/videoContext';

ReactDOM.render(
  <UserProvider>
    <VidoProvider>
      <App />
    </VidoProvider>
  </UserProvider>,
  document.getElementById('root'),
);
