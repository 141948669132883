import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
  ${reset}
  
  body  { 
    font-family: 'NanumBarunGothic', sans-serif; 
    box-sizing : border-box;
  }

  input {
    appearance: none;
    -webkit-appearance: none;
  }

  @font-face {
  font-family: HGGothicssi_Pro_40g;
  src: url(/font/HGGothicssi_Pro_40g.eot);
  src: url(/font/HGGothicssi_Pro_40g.eot#iefix) format('embedded-opentype'),
       url(/font/HGGothicssi_Pro_40g.woff2) format('woff2'),
       url(/font/HGGothicssi_Pro_40g.woff) format('woff'),
       url(/font/HGGothicssi_Pro_40g.ttf) format('truetype');
  }
  @font-face {
  font-family: HGGothicssi_Pro_80g;
  src: url(/font/HGGothicssi_Pro_80g.eot);
  src: url(/font/HGGothicssi_Pro_80g.eot#iefix) format('embedded-opentype'),
       url(/font/HGGothicssi_Pro_80g.woff2) format('woff2'),
       url(/font/HGGothicssi_Pro_80g.woff) format('woff'),
       url(/font/HGGothicssi_Pro_80g.ttf) format('truetype');
  }
`;

export default GlobalStyle;
