import React, { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import smoothscroll from 'smoothscroll-polyfill';

import * as services from 'services';
import shuffleQuestion from 'utils/shuffleQuestion';
import { Loading, Snackbar } from 'components';
import { TestStart } from '../containers';
import { TestInfo, TestLayout } from '../components';

const Test = () => {
  const location = useLocation();
  const [questions, setQuestions] = useState([]);
  const [fetchState, setFetchState] = useState('none');

  const fetchQuestions = useCallback(async () => {
    try {
      setFetchState('loading');
      const response = await services.fetchQuestions();
      setQuestions(shuffleQuestion(response.data.questions));
      setFetchState('success');
    } catch (error) {
      setFetchState('error');
    }
  }, []);

  useEffect(() => {
    smoothscroll.polyfill();
    fetchQuestions();
  }, []);

  return (
    <>
      <Helmet>
        <title>프로진로고민러 진로 고민 유형 테스트</title>
        <meta
          property="og:url"
          content={`https://progominler.com${location.pathname}`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="프로진로고민러 진로 고민 유형 테스트"
        />
        <meta
          property="og:description"
          content="나의 진로 고민 유형은? 지금 바로 내 유형을 확인해보세요!"
        />
        <meta
          property="og:image"
          content="https://pg-image-public.s3.ap-northeast-2.amazonaws.com/share/test_thumnail.png"
        />
      </Helmet>
      {
        {
          loading: fetchState === 'loading' && <Loading />,
          error: fetchState === 'error' && (
            <Snackbar
              text="문제가 발생했습니다. 잠시후에 다시 시도해주세요."
              isError
            />
          ),
          success: fetchState === 'success' && (
            <TestLayout>
              <TestInfo />
              <TestStart questions={questions} setQuestions={setQuestions} />
            </TestLayout>
          ),
        }[fetchState]
      }
    </>
  );
};

export default Test;
