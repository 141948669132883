import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, font, mobileSize } from 'styles/variables';

const S = {
  RankItem: styled.div`
    width: 100%;
    padding: 1.5rem 0;
    border-bottom: 1px solid ${color.gray02};
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    ${mobileSize} {
      padding: 2rem 0;
    }
  `,
  RankNumber: styled.div`
    font-family: 'HGGothicssi_Pro_80g', 'NanumBarunGothic', sans-serif;
    font-size: ${font.title};
    margin-right: 2rem;
    ${mobileSize} {
      font-size: ${font.question};
    }
  `,
  TextContainer: styled.div`
    position: absolute;
    left: 4rem;
    ${mobileSize} {
      left: 2.5rem;
    }
  `,
  TypeTitle: styled.div`
    font-weight: 700;
    margin-bottom: 0.5rem;
    ${mobileSize} {
      font-size: ${font.button};
    }
  `,
  TypeText: styled.div`
    font-size: ${font.button};
    ${mobileSize} {
      font-size: ${font.small};
    }
  `,
  RateNumber: styled.div`
    font-family: 'HGGothicssi_Pro_40g', 'NanumBarunGothic', sans-serif;
    font-size: ${font.title};
    margin-left: auto;
    ${mobileSize} {
      font-size: ${font.question};
    }
  `,
};

const RankItem = ({ index, name, description, rate }) => {
  return (
    <S.RankItem>
      <S.RankNumber>0{index}</S.RankNumber>
      <S.TextContainer>
        <S.TypeTitle>{name}</S.TypeTitle>
        <S.TypeText>{description}</S.TypeText>
      </S.TextContainer>
      <S.RateNumber>{rate}%</S.RateNumber>
    </S.RankItem>
  );
};

RankItem.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  rate: PropTypes.number.isRequired,
};

export default RankItem;
