import React, { createContext, useReducer, useContext } from 'react';
import Proptype from 'prop-types';

import reducer, { initialState } from './userReducer';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserDispatch = () => {
  const { dispatch } = useContext(UserContext);
  return dispatch;
};

export const useUserState = () => {
  const { state } = useContext(UserContext);
  return state;
};

UserProvider.propTypes = {
  children: Proptype.element.isRequired,
};

export default UserProvider;
