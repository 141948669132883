import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import * as services from 'services';
import { Loading, Snackbar } from 'components';
import {
  Report,
  Product,
  ResultInfo,
  Chart,
  Rank,
  ResultButtons,
  ResultLayout,
  Notice,
} from '../components';

const Result = () => {
  const { resultId } = useParams();
  const [result, setResult] = useState({});
  const [fetchState, setFetchState] = useState('none');
  const location = useLocation();

  const fetchData = useCallback(async () => {
    try {
      setFetchState('loading');
      const results = await services.fetchResult(resultId);
      setResult(results.data.response);
      setFetchState('success');
    } catch (e) {
      console.log(e);
      setFetchState('error');
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const shareKakaotalk = () => {
    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: '프로진로고민러 진로 고민 유형 테스트',
        description: `나의 진로 고민 유형은? ${result.title} 유형`,
        imageUrl: `https://pg-image-public.s3.ap-northeast-2.amazonaws.com/share/kakaotalk_result_${result.typeNumber}.png`,
        link: {
          mobileWebUrl: `https://progominler.com${location.pathname}`,
          webUrl: `https://progominler.com${location.pathname}`,
        },
      },
      buttons: [
        {
          title: '테스트 결과 보기',
          link: {
            mobileWebUrl: `https://progominler.com${location.pathname}`,
            webUrl: `https://progominler.com${location.pathname}`,
          },
        },
        {
          title: '테스트 하러 가기',
          link: {
            mobileWebUrl: 'https://progominler.com/test',
            webUrl: 'https://progominler.com/test',
          },
        },
      ],
    });
  };

  return (
    <>
      {
        {
          loading: fetchState === 'loading' && <Loading />,
          error: fetchState === 'error' && (
            <Snackbar
              text="문제가 발생했습니다. 잠시후에 다시 시도해주세요."
              isError
            />
          ),
          success: fetchState === 'success' && (
            <>
              <Helmet>
                <title>프로진로고민러: {result.title} 유형</title>
                <meta
                  property="og:url"
                  content={`https://progominler.com${location.pathname}`}
                />
                <meta property="og:type" content="website" />
                <meta
                  property="og:title"
                  content="프로진로고민러 진로 고민 유형 테스트"
                />
                <meta
                  property="og:description"
                  content={`나의 진로 고민 유형은? ${result.title} 유형`}
                />
                <meta
                  property="og:image"
                  content={`https://pg-image-public.s3.ap-northeast-2.amazonaws.com/share/facebook_result_${result.typeNumber}.png`}
                />
              </Helmet>
              <ResultLayout>
                <ResultInfo result={result} />
                <Chart result={result} fetchState={fetchState} />
                <Rank result={result} />
                <Report title={result.title} text={result.text} />
                <Product products={result.products} />
                <ResultButtons shareKakaotalk={shareKakaotalk} />
                <Notice />
              </ResultLayout>
            </>
          ),
        }[fetchState]
      }
    </>
  );
};

export default Result;
