import { LOAD_VIDEO_REQUEST, LOAD_VIDEO_SUCCESS } from './videoActions';

export const initialState = {
  video: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case LOAD_VIDEO_REQUEST:
      return {
        ...state,
      };
    case LOAD_VIDEO_SUCCESS:
      return {
        ...state,
        video: [...action.payload],
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
