import styled from 'styled-components';
import { mobileSize } from 'styles/variables';

const TestLayout = styled.div`
  width: 100%;
  max-width: 780px;
  margin: auto;
  padding: 100px 0 120px 0;
  box-sizing: border-box;
  ${mobileSize} {
    padding: 2rem 1rem;
  }
`;

export default TestLayout;
