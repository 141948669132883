import React from 'react';
import styled from 'styled-components';

import { img_test_01, img_test_02, img_test_03 } from 'assets';
import { color, font, mobileSize } from 'styles/variables';

const S = {
  TestInfo: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5rem;
    ${mobileSize} {
      margin-bottom: 1rem;
    }
  `,
  SubTitle: styled.h2`
    font-family: 'HGGothicssi_Pro_40g', 'NanumBarunGothic', sans-serif;
    font-size: ${font.title};
    margin-bottom: 0.5rem;
    ${mobileSize} {
      font-size: ${font.question};
    }
  `,
  Title: styled.h1`
    font-family: 'HGGothicssi_Pro_80g', 'NanumBarunGothic', sans-serif;
    font-size: ${font.title};
    margin-bottom: 1.5rem;
    ${mobileSize} {
      font-size: ${font.question};
    }
  `,
  Text: styled.p`
    width: 80%;
    line-height: 1.5;
    text-align: center;
    font-size: ${font.button};
    color: ${color.gray07};
    margin-bottom: 5rem;
    ${mobileSize} {
      margin-bottom: 2.5rem;
    }
  `,
  SubContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${mobileSize} {
      flex-direction: column;
      align-items: center;
    }
  `,
  Item: styled.div`
    width: 180px;
    text-align: center;
    ${mobileSize} {
      margin-bottom: 2.5rem;
    }
  `,
  Image: styled.img`
    width: 7.5rem;
    margin-bottom: 2rem;
    ${mobileSize} {
      width: 5rem;
      margin-bottom: 1rem;
    }
  `,
  ItemText: styled.p`
    font-weight: 700;
    color: ${color.gray07};
    line-height: 1.5;
    letter-spacing: -0.5px;
    word-break: keep-all;
  `,
};

const TestInfo = () => {
  return (
    <S.TestInfo>
      <S.SubTitle>당신의 유형은 무엇인가요?</S.SubTitle>
      <S.Title>진로 고민 유형 테스트</S.Title>
      <S.Text>
        고민에도 다양한 종류가 있어요. 근육을 기르기 위해 부위별 운동을 하듯이,
        고민 유형에 맞는 고민 근육을 기르기 위해서는 먼저 자신의 고민 유형을
        정확하게 아는 것이 중요합니다. 테스트를 통해 고민 유형을 확인해보세요!
      </S.Text>
      <S.SubContainer>
        <S.Item>
          <S.Image src={img_test_01} alt="icon" />
          <S.ItemText>소요시간은 총 5분이에요</S.ItemText>
        </S.Item>
        <S.Item>
          <S.Image src={img_test_02} alt="icon" />
          <S.ItemText>가능하면 답변 시 ‘중립’을 선택하지 말아주세요</S.ItemText>
        </S.Item>
        <S.Item>
          <S.Image src={img_test_03} alt="icon" />
          <S.ItemText>너무 많은 생각을 하기보다는 바로 선택해주세요</S.ItemText>
        </S.Item>
      </S.SubContainer>
    </S.TestInfo>
  );
};

export default TestInfo;
