import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, font } from 'styles/variables';

const S = {
  Container: styled.div`
    width: 100%;
    max-width: 1180px;
    display: flex;
    flex-direction: row;
    padding: 1.5rem 0;
    margin: 2rem auto;
  `,
  Tab: styled.button`
    border: none;
    padding: 0;
    margin-right: 2rem;
    font-size: ${font.button};
    color: ${color.gray05};
  `,
};

const TabBar = ({ props }) => {
  return (
    <S.Container>
      <S.Tab>전체 상품</S.Tab>
      <S.Tab>진로 코칭 플래너</S.Tab>
      <S.Tab>노트 / 메모</S.Tab>
      <S.Tab>카드 / 엽서</S.Tab>
    </S.Container>
  );
};

TabBar.propTypes = {};

export default TabBar;
