import { user } from 'assets';
import {
  LOG_IN_SUCCESS,
  LOG_IN_FAILURE,
  LOG_OUT_REQUEST,
  LOAD_USER_SUCCESS,
  SIGN_UP_SUCCESS,
  EDIT_USER_SUCCESS,
  LOG_IN_REQUEST,
  LOAD_USER_REQUEST,
  LOAD_USER_FAILURE,
  EDIT_USER_REQUEST,
  EDIT_USER_FAILURE,
} from './userActions';

export const initialState = {
  isLogin: false,
  pk: '',
  name: '',
  email: '',
  profileImage: user,
  mobile: '',
  birthday: '',
  gender: '',
  marketing_email: false,
  marketing_sms: false,
  loading: false,
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case LOG_IN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOG_IN_SUCCESS:
      return {
        ...state,
        isLogin: true,
        loading: false,
      };
    case LOG_IN_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case LOAD_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        isLogin: true,
        name: action.payload.name,
        email: action.payload.email,
        mobile: action.payload.mobile,
        birthday: action.payload.birthday,
        gender: action.payload.gender,
        marketing_email: action.payload.marketing_email,
        marketing_sms: action.payload.marketing_sms,
        loading: false,
      };
    case LOAD_USER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case LOG_OUT_REQUEST:
      return {
        ...state,
        isLogin: false,
        name: '',
        email: '',
        profileImage: user,
        mobile: '',
        birthday: '',
        gender: '',
        marketing_email: false,
        marketing_sms: false,
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        isLogin: true,
        name: action.payload.name,
        email: action.payload.email,
        mobile: action.payload.mobile,
        birthday: action.payload.birthday,
        gender: action.payload.gender,
        marketing_email: action.payload.marketing_email,
        marketing_sms: action.payload.marketing_sms,
      };

    case EDIT_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        name: action.payload.name,
        email: action.payload.email,
        // profileImg: 'action.payload.profile',
        mobile: action.payload.mobile,
        birthday: action.payload.birthday,
        gender: action.payload.gender,
        marketing_email: action.payload.marketing_email,
        marketing_sms: action.payload.marketing_sms,
        loading: false,
      };
    case EDIT_USER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    default:
      return {
        ...state,
      };
  }
};

export default reducer;
