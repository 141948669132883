import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { color, font, mobileSize } from 'styles/variables';

const S = {
  Background: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  `,
  Container: styled.div`
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 1001;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${color.white};
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
    transition: 0.5s ease-in-out all;
    transform: ${props =>
      props.isOpen ? 'translateY(0)' : 'translateY(-150%)'};
  `,
  MenuIcon: styled.div`
    position: fixed;
    top: 1rem;
    right: 1rem;
    width: 22px;
    height: 22px;
    z-index: 2001;
    margin-left: auto;
    cursor: pointer;
    padding: 4px 2px;
    box-sizing: border-box;
    display: none;
    ${mobileSize} {
      display: block;
    }
  `,
  Icon: styled.div`
    width: 18px;
    height: 2px;
    border-radius: 1px;
    position: relative;
    transform: translateY(10px);
    background: rgba(0, 0, 0, 1);
    transition: all 0ms 300ms;
    &::after {
      width: 18px;
      height: 2px;
      border-radius: 1px;
      content: '';
      position: absolute;
      left: 0;
      top: 6px;
      background: rgba(0, 0, 0, 1);
      transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
        transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    &::before {
      width: 18px;
      height: 2px;
      border-radius: 1px;
      content: '';
      position: absolute;
      left: 0;
      bottom: 6px;
      background: rgba(0, 0, 0, 1);
      transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
        transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    ${props =>
      props.isOpen &&
      `
      background: rgba(0,0,0,0);
      &::after {
          top: 0;
          transform: rotate(45deg);
          transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
        }

        &::before {
          bottom: 0;
          transform: rotate(-45deg);
          transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
        }
    `}
  `,
  Link: styled(Link)`
    width: 100%;
    display: block;
    padding: 1.5rem 0;
    text-align: center;
    text-decoration: none;
    color: ${color.gray07};
    font-size: ${font.button};
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;

      margin: 0 1rem;
      border-bottom: 1px solid ${color.gray02};
    }
    &:active {
      background-color: ${color.gray02};
    }
  `,
  Button: styled.div`
    display: inline-block;
    background-color: ${color.main};
    color: ${color.white};
    padding: 1rem 2rem;
    border-radius: 3rem;
    margin: 1.5rem 0;
    font-size: ${font.button};
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  `,
};

const GlobalMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <S.MenuIcon isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <S.Icon isOpen={isOpen} />
      </S.MenuIcon>
      <S.Container isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <S.Link to="/about">프로진로고민러 소개</S.Link>
        <S.Link to="/test">고민 유형 테스트</S.Link>
        <S.Link to="/video">영상 코칭 가이드</S.Link>
        <S.Link to="/store">온라인 스토어</S.Link>
        <S.Link to="/login">로그인</S.Link>
        <Link to="/test">
          <S.Button>진로 고민 시작하기</S.Button>
        </Link>
      </S.Container>
      {isOpen && (
        <>
          <S.Background toggle={isOpen} onClick={() => setIsOpen(false)} />
        </>
      )}
    </>
  );
};

GlobalMenu.propTypes = {};

export default GlobalMenu;
