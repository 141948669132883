import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import uuid from 'uuid/v4';

import sortResult from 'utils/sortResult';
import RankItem from './RankItem';

const S = {
  Container: styled.div`
    width: 100%;
    max-width: 580px;
    margin-bottom: 5rem;
  `,
};

const Rank = ({ result }) => {
  return (
    <S.Container>
      {sortResult(result).map((type, index) => (
        <RankItem
          key={uuid()}
          index={index + 1}
          name={type.name}
          description={type.description}
          rate={type.rate}
        />
      ))}
    </S.Container>
  );
};

Rank.propTypes = {
  result: PropTypes.shape({
    type: PropTypes.array,
  }).isRequired,
};

export default Rank;
