export const color = {
  black: '#000000',
  white: '#ffffff',
  main: '#E15623',
  gray02: '#ededed',
  gray05: '#858a8d',
  gray07: '#4d5256',
  darkgray: '#121212',
  red: '#f24147',
};

export const font = {
  tiny: '0.625rem',
  small: '0.75rem',
  button: '0.875rem',
  normal: '1rem',
  question: '1.25rem',
  large: '1.5rem',
  title: '2rem',
};

const BREAK_POINT_MOBILE = 575;
const BREAK_POINT_TABLET = 768;
const BREAK_POINT_PC = 1024;

export const mobileSize = `@media (max-width: ${BREAK_POINT_MOBILE}px)`;
export const tabletSize = `@media (max-width: ${BREAK_POINT_TABLET}px)`;
export const desktopSize = `@media (max-width: ${BREAK_POINT_PC}px)`;
