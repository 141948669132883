import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import * as services from 'services';
import { Snackbar } from 'components';
import ResetPassword from '../components/ResetPassword';

const Reset = () => {
  const location = useLocation();
  const history = useHistory();
  const { email, token } = queryString.parse(location.search);
  const [error, setError] = useState('');
  const [isSuccess, setIsSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const snackbarMessage = useMemo(() => {
    if (error && error.status === 500) {
      return '링크가 만료되었습니다. 재설정 이메일을 다시 보내주세요.';
    }
    if (isSuccess) {
      return '비밀번호가 재설정되었습니다. 다시 로그인해주세요.';
    }
    return '문제가 발생했습니다. 잠시후에 다시 시도해주세요.';
  });

  const fetchData = useCallback(async data => {
    try {
      setIsLoading(true);
      // eslint-disable-next-line
      const response = await services.fetchPassword({
        email,
        token,
        ...data,
      });
      setIsSuccess(true);
      setIsLoading(false);
    } catch (e) {
      setError(e.response);
      setIsLoading(false);
    }
  }, []);

  const handleSubmit = values => {
    fetchData(values);
  };

  // eslint-disable-next-line
  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        history.push('/login');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);

  return (
    <>
      {error && <Snackbar text={snackbarMessage} isError={error} />}
      {isSuccess && <Snackbar text={snackbarMessage} />}
      <ResetPassword onSubmit={handleSubmit} isLoading={isLoading} />
    </>
  );
};

export default Reset;
