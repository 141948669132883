import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color } from 'styles/variables';

const S = {
  Container: styled.div`
    width: 100%;
    margin: 120px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
  `,
  Item: styled.div`
    width: 3rem;
    height: 3rem;
    text-align: center;
    line-height: 3rem;
    border-radius: 50%;
    margin: 0 0.5rem;
    &:first-child {
      background-color: ${color.darkgray};
      color: ${color.white};
    }
  `,
};

const Pagination = props => {
  return (
    <S.Container>
      <S.Item>1</S.Item>
      <S.Item>2</S.Item>
      <S.Item>3</S.Item>
    </S.Container>
  );
};

Pagination.propTypes = {};

export default Pagination;
