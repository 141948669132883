import styled from 'styled-components';
import { mobileSize } from 'styles/variables';

const ResultLayout = styled.div`
  width: 100%;
  max-width: 1180px;
  margin: auto;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  ${mobileSize} {
    padding: 2rem 1rem;
  }
`;

export default ResultLayout;
