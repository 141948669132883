import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, font } from 'styles/variables';
import { icon_checkbox_checked } from 'assets';

const S = {
  Item: styled.label`
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  HideCheckbox: styled.input`
    width: 100%;
    height: 100%;
    margin: 0;
    -webkit-appearance: none;
    box-shadow: none;
    display: none;
    &:checked + label {
      border-color: ${color.darkgray};
      background-image: url(${icon_checkbox_checked});
      background-position: center, center;
    }
  `,
  Label: styled.label`
    display: inline-block;
    width: 1.625rem;
    height: 1.625rem;
    border-radius: 0.5rem;
    border: 2px solid ${color.gray05};
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Text: styled.span`
    color: ${color.gray07};
    font-size: ${font.button};
    margin-left: 1rem;
  `,
};

const Checkbox = ({
  field: { name, value, onChange, onBlur },
  style,
  id,
  label,
  ...props
}) => {
  return (
    <S.Item style={style}>
      <S.HideCheckbox
        name={name}
        id={id}
        type="checkbox"
        value={value}
        checked={value}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
      <S.Label htmlFor={id} />
      <S.Text>{label}</S.Text>
    </S.Item>
  );
};

Checkbox.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.string),
};

Checkbox.defaultProps = {
  style: {},
  field: {},
};

export default Checkbox;
