import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Route, Switch, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { Login } from 'features/auth/pages';
import { Test } from 'features/type-test/pages';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const S = {
  Container: styled.div`
    opacity: 1;
    &.page-enter {
      animation: ${fadeIn} 500ms ease-in-out;
    }
    &.page-exit {
      animation: ${fadeOut} 500ms ease-in-out;
    }
  `,
  Background: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: 0;
    &.page-enter {
      animation: ${fadeIn} 500ms ease-in-out;
    }
    &.page-exit {
      animation: ${fadeOut} 500ms ease-in-out;
    }
  `,
};

const PageTransition = () => {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition timeout={1000} classNames="page" key={location.key}>
        <>
          <S.Background />
          <S.Container>
            <Switch location={location}>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/test">
                <Test />
              </Route>
            </Switch>
          </S.Container>
        </>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default PageTransition;
