const sortResult = data => {
  const sortByNum = data.type.sort((a, b) => {
    if (a.rate > b.rate) {
      return -1;
    }
    if (a.rate < b.rate) {
      return 1;
    }
    return 0;
  });
  return sortByNum.slice(0, 3);
};

export default sortResult;
