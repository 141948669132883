import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';

import { color, font, mobileSize } from 'styles/variables';

const S = {
  SubTitle: styled.h3`
    font-size: ${font.large};
    font-weight: 700;
    margin-bottom: 4rem;
    ${mobileSize} {
      font-size: ${font.question};
    }
  `,
  Container: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10rem;
    ${mobileSize} {
      width: fit-content;
      margin: auto;
      flex-direction: column;
      margin-bottom: 4rem;
    }
  `,
  Item: styled.a`
    display: inline-block;
    text-decoration: none;
    width: 100%;
    max-width: 380px;
    ${mobileSize} {
      display: flex;
      flex-direction: row;
    }
  `,
  Img: styled.div`
    width: 380px;
    height: 380px;
    margin-bottom: 1.5rem;
    background: url(${props => props.image}) no-repeat;
    background-size: cover;
    ${mobileSize} {
      width: 120px;
      height: 120px;
      min-width: 120px;
      min-height: 120px;
      margin-right: 1rem;
      flex-grow: 1;
    }
  `,
  TextContainer: styled.div`
    display: flex;
    flex-direction: column;
    ${mobileSize} {
      flex-grow: 2;
      height: 120px;
    }
  `,
  Rank: styled.div`
    font-family: 'HGGothicssi_Pro_80g', 'NanumBarunGothic', sans-serif;
    font-size: ${font.large};
    color: ${color.darkgray};
    margin-bottom: 0.875rem;
    ${mobileSize} {
      font-size: ${font.question};
      margin-bottom: 0.5rem;
    }
  `,
  Name: styled.div`
    font-weight: 700;
    margin-bottom: 1rem;
    color: ${color.darkgray};
    ${mobileSize} {
      font-size: ${font.button};
      margin-bottom: 0.5rem;
    }
  `,
  Description: styled.div`
    color: ${color.gray05};
    font-size: ${font.button};
    line-height: 1.6;
    margin-bottom: 2rem;
    ${mobileSize} {
      font-size: ${font.tiny};
      margin: 0;
    }
  `,
  Price: styled(NumberFormat)`
    display: inline-block;
    font-weight: 700;
    color: ${color.main};
    margin-bottom: 1rem;
    ${mobileSize} {
      font-size: ${font.tiny};
    }
  `,
  TagContainer: styled.div`
    margin-top: auto;
  `,
  Tag: styled.div`
    display: inline-block;
    font-size: ${font.small};
    background-color: ${color.gray02};
    color: ${color.gray05};
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    margin-right: 0.75rem;
    ${mobileSize} {
      font-size: ${font.tiny};
      padding: 5px 10px;
      margin-right: 5px;
    }
  `,
};

const Product = ({ products }) => (
  <>
    <S.SubTitle>당신을 위한 프로진로고민러</S.SubTitle>
    <S.Container>
      {products.map((item, index) => (
        <S.Item key={item._id} href="https://smartstore.naver.com/progominler/products/4806615382">
          <S.Img image={item.main_image} />
          <S.TextContainer>
            <S.Rank>0{index + 1}</S.Rank>
            <S.Name>{item.name}</S.Name>
            <S.Description>{item.summary}</S.Description>
            <S.TagContainer>
              {item.tags.map(text => (
                <S.Tag key={`${text}`}>{text}</S.Tag>
              ))}
            </S.TagContainer>
          </S.TextContainer>
        </S.Item>
      ))}
    </S.Container>
  </>
);

Product.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Product;
