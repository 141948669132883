import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { color, font, mobileSize } from 'styles/variables';

const S = {
  TitleText: styled.div`
    font-family: 'HGGothicssi_Pro_40g', 'NanumBarunGothic', sans-serif;
    font-size: ${font.title};
    margin-bottom: 0.5rem;
    ${mobileSize} {
      font-size: ${font.question};
    }
  `,
  Title: styled.h1`
    font-family: 'HGGothicssi_Pro_80g', 'NanumBarunGothic', sans-serif;
    font-size: ${font.title};
    margin-bottom: 1.5rem;
    ${mobileSize} {
      font-size: ${font.question};
    }
  `,
  Description: styled.h2`
    color: ${color.darkgray};
    margin-bottom: 2rem;
    position: relative;
    font-weight: 700;
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0.5rem;
      left: 0;
      width: 100%;
      height: 1rem;
      background-color: rgba(255, 86, 35, 0.2);
    }
    ${mobileSize} {
      font-size: ${font.button};
    }
  `,
  Image: styled.img`
    width: 100%;
    max-width: 380px;
    margin-bottom: 7.5rem;
  `,
  SubTitle: styled.h3`
    font-size: ${font.large};
    font-weight: 700;
    margin-bottom: 4rem;
    ${mobileSize} {
      font-size: ${font.question};
      margin-bottom: 2.5rem;
    }
  `,
};

const ResultInfo = ({ result }) => {
  return (
    <>
      <S.TitleText>나의 진로 고민 유형은?</S.TitleText>
      <S.Title>{result.title} 유형</S.Title>
      <S.Description>&quot;{result.description}&quot;</S.Description>
      <S.Image src={result.image} alt="result" />
      <S.SubTitle>진로 고민 유형 상세 분석</S.SubTitle>
    </>
  );
};

ResultInfo.propTypes = {
  result: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
};

export default ResultInfo;
