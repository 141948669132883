import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field, ErrorMessage } from 'formik';

import { color, font } from 'styles/variables';

const S = {
  Container: styled.div`
    width: 100%;
    margin-top: 1.5rem;
  `,
  Label: styled.div`
    color: ${color.gray07};
    font-size: ${font.button};
    margin-bottom: 0.5rem;
  `,
  Field: styled(Field)`
    width: 100%;
    box-sizing: border-box;
    font-size: ${font.button};
    padding: 1rem 1.5rem;
    outline: none;
    border: solid 1px #ccc;
    border-radius: 3rem;
    :focus {
      border-color: ${color.gray07};
    }
  `,
  ErrorMessage: styled.div`
    font-size: ${font.small};
    color: ${color.red};
    margin-top: 0.5rem;
  `,
};

const TextField = ({ name, label, type, placeholder, ...props }) => {
  return (
    <S.Container>
      <S.Label>{label}</S.Label>
      <S.Field name={name} type={type} placeholder={placeholder} {...props} />
      <ErrorMessage component={S.ErrorMessage} name={name} />
    </S.Container>
  );
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
};

TextField.defaultProps = {
  type: 'text',
};

export default TextField;
