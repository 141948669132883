import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useUserState, useUserDispatch } from 'contexts/userContext';
import { color, font } from 'styles/variables';
import { LOG_OUT_REQUEST } from 'contexts/userActions';

const S = {
  Background: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    opacity: 0;
  `,
  Container: styled.div`
    display: inline-block;
    position: absolute;
    top: 86px;
    right: 30px;
    width: 100%;
    max-width: 320px;
    background-color: ${color.white};
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    z-index: 1001;
  `,
  Profile: styled.div`
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid ${color.gray02};
  `,
  ProfileImg: styled.img`
    width: 2.875rem;
    margin-right: 1rem;
  `,
  UserInfo: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  UserName: styled.p`
    font-weight: 700;
    color: ${color.gray07};
    margin-bottom: 0.5rem;
  `,
  Text: styled.p`
    font-size: ${font.button};
    color: ${color.gray07};
  `,
  LinkContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Link: styled(Link)`
    font-size: ${font.button};
    padding: 1.5rem 2rem;
    color: ${color.gray07};
    text-decoration: none;
  `,
  Button: styled.div`
    font-size: ${font.button};
    padding: 1.5rem 2rem;
    color: ${color.gray07};
    text-decoration: none;
    cursor: pointer;
  `,
};

const UserMenu = ({ toggle, setToggle }) => {
  const { name, email, profileImage } = useUserState();
  const dispatch = useUserDispatch();
  const history = useHistory();

  const onClick = () => {
    setToggle(!toggle);
  };

  const onLogout = () => {
    localStorage.removeItem('token');
    dispatch({ type: LOG_OUT_REQUEST });
    history.push('/');
  };

  return (
    <>
      {toggle && (
        <>
          <S.Background onClick={onClick} />
          <S.Container>
            <S.Profile>
              <S.ProfileImg src={profileImage} alt="user profile" />
              <S.UserInfo>
                <S.UserName>{name}</S.UserName>
                <S.Text>{email}</S.Text>
              </S.UserInfo>
            </S.Profile>
            <S.LinkContainer onClick={onClick}>
              <S.Link to="/video">영상 코칭 가이드 코드 등록</S.Link>
              <S.Link to="/profile">내 정보</S.Link>
              <S.Button onClick={onLogout}>로그아웃</S.Button>
            </S.LinkContainer>
          </S.Container>
        </>
      )}
    </>
  );
};

UserMenu.propTypes = {
  toggle: PropTypes.bool.isRequired,
  setToggle: PropTypes.func.isRequired,
};

export default UserMenu;
