import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { color, font } from 'styles/variables';

import TextField from 'components/TextField';
import NumericField from 'components/NumericField';
import RadioButton from 'components/RadioButton';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';

const S = {
  Container: styled.div`
    width: 100%;
    max-width: 380px;
    margin: auto;
    padding: 120px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  Title: styled.h1`
    color: ${color.darkgray};
    font-size: ${font.large};
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.5rem;
  `,
  Form: styled(Form)`
    width: 100%;
  `,
  Link: styled(Link)`
    margin: 3rem 0 1rem 0;
    display: block;
    text-align: center;
    font-size: ${font.small};
    color: ${color.gray05};
    text-decoration: none;
    span {
      font-weight: 700;
      text-decoration: underline;
    }
  `,
  Button: styled.button`
    width: 100%;
    font-size: ${font.button};
    padding: 1rem 1.5rem;
    background-color: ${color.darkgray};
    color: ${color.white};
    border-radius: 3rem;
    cursor: pointer;
    :focus {
      outline: none;
    }
  `,
  ItemContainer: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  RadioContainer: styled.div`
    margin-top: 1.5rem;
    width: 100%;
    margin-left: 20px;
  `,
  ButtonContainer: styled.div`
    padding: 0.625rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  Label: styled.div`
    color: ${color.gray07};
    font-size: ${font.button};
    margin-bottom: 0.5rem;
  `,
  ErrorMessage: styled.div`
    font-size: ${font.small};
    color: ${color.red};
    margin-top: 0.5rem;
  `,
  CheckboxContainer: styled.div`
    padding: 0.625rem 0;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  Checkbox: styled.div`
    width: 100%;
    &:last-child {
      margin-left: 20px;
    }
  `,
};

const SignupForm = ({ onSubmit, fetchState }) => {
  return (
    <S.Container>
      <S.Title>회원가입</S.Title>
      <Formik
        initialValues={{
          email: '',
          password: '',
          passwordCheck: '',
          name: '',
          mobile: '',
          birthday: '',
          gender: '',
          marketing_email: false,
          marketing_sms: false,
        }}
        validate={values => {
          const errors = {};
          if (!values.email) {
            errors.email = '이메일을 입력해주세요.';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = '올바른 이메일 형식이 아닙니다.';
          }
          if (!values.password) {
            errors.password = '비밀번호를 입력해주세요.';
          } else if (
            !/^(?=.*[a-zA-Z])(?=.*[0-9]).{8,12}$/.test(values.password)
          ) {
            errors.password =
              '영문, 숫자를 포함한 8자리 이상의 비밀번호를 설정해주세요.';
          }
          if (values.passwordCheck !== values.password) {
            errors.passwordCheck = '비밀번호가 일치하지 않습니다.';
          } else if (!values.passwordCheck) {
            errors.passwordCheck = '비밀번호를 확인해주세요.';
          }
          if (!values.name) {
            errors.name = '이름을 입력해주세요.';
          }
          if (!values.mobile) {
            errors.mobile = '전화번호를 입력해주세요.';
          }
          if (!values.birthday) {
            errors.birthday = '생년월일을 입력해주세요.';
          }
          if (!values.gender) {
            errors.gender = '성별을 입력해주세요.';
          }
          return errors;
        }}
        onSubmit={onSubmit}
      >
        {(
          { values, errors, touched, isValidating, setFieldValue }, // eslint-disable-line
        ) => (
          <S.Form>
            <TextField
              name="email"
              label="이메일"
              placeholder="example@email.com"
              inputMode="email"
            />
            <TextField
              name="password"
              label="비밀번호"
              type="password"
              placeholder="**********"
            />
            <TextField
              name="passwordCheck"
              label="비밀번호 확인"
              type="password"
              placeholder="**********"
            />
            <TextField name="name" label="이름" placeholder="홍길동" />
            <NumericField
              name="mobile"
              label="전화번호"
              value={values.mobile}
              placeholder="010 1234 5678"
              format="### #### ####"
              onValueChange={val => setFieldValue('mobile', val.formattedValue)}
            />
            <S.ItemContainer>
              <NumericField
                name="birthday"
                label="생년월일"
                value={values.birthday}
                placeholder="YYYY-MM-DD"
                format="####-##-##"
                onValueChange={val =>
                  setFieldValue('birthday', `${val.formattedValue}T00:00:00Z`)}
              />
              <S.RadioContainer>
                <S.Label htmlFor="gender">성별</S.Label>
                <S.ButtonContainer>
                  <Field
                    component={RadioButton}
                    name="gender"
                    id="female"
                    label="여성"
                  />
                  <Field
                    component={RadioButton}
                    name="gender"
                    id="male"
                    label="남성"
                  />
                </S.ButtonContainer>
                <ErrorMessage component={S.ErrorMessage} name="gender" />
              </S.RadioContainer>
            </S.ItemContainer>
            <S.CheckboxContainer>
              <S.Checkbox>
                <Field
                  component={Checkbox}
                  name="marketing_email"
                  id="marketing_email"
                  label="이메일 수신 동의"
                />
              </S.Checkbox>
              <S.Checkbox>
                <Field
                  component={Checkbox}
                  name="marketing_sms"
                  id="marketing_sms"
                  label="SMS 수신 동의"
                />
              </S.Checkbox>
            </S.CheckboxContainer>
            <S.Link to="/terms">
              진행하면 <span>약관</span> 및 <span>개인정보 처리 방침</span>에
              동의하게 됩니다.
            </S.Link>
            <Button
              type="submit"
              width="100%"
              text="회원가입"
              fill="solid"
              fetchState={fetchState}
            />
            <S.Link to="/login">
              이미 계정이 있으신가요? <span>로그인</span>
            </S.Link>
          </S.Form>
        )}
      </Formik>
    </S.Container>
  );
};

SignupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fetchState: PropTypes.string.isRequired,
};

export default SignupForm;
