import React, { createContext, useReducer, useContext } from 'react';
import Proptype from 'prop-types';

import reducer, { initialState } from './videoReducer';

const VideoContext = createContext();
const VideoProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <VideoContext.Provider value={{ state, dispatch }}>
      {children}
    </VideoContext.Provider>
  );
};

export const useVideoDispatch = () => {
  const { dispatch } = useContext(VideoContext);
  return dispatch;
};

export const useVideoState = () => {
  const { state } = useContext(VideoContext);
  return state;
};

VideoProvider.propTypes = {
  children: Proptype.element.isRequired,
};

export default VideoProvider;
