import React from 'react';
import styled from 'styled-components';

import { color, font } from 'styles/variables';

const S = {
  Notice: styled.div`
    font-size: ${font.small};
    color: ${color.gray05};
    line-height: 1.5;
    text-align: center;
  `,
};

const Notice = () => (
  <S.Notice>
    진로 고민 유형 테스트는 프로진로고민러의 지적 재산이며 무단으로 사용하거나
    도용할 시 법적인 책임을 질 수 있습니다.
  </S.Notice>
);

export default Notice;
