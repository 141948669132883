import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';

import { color, font } from 'styles/variables';
import { Checkbox, Textarea } from 'components';
import { icon_close } from 'assets';

const S = {
  Background: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.6);
  `,
  Container: styled.div`
    width: 100%;
    max-width: 580px;
    padding: 3rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    background-color: ${color.white};
  `,
  CloseIcon: styled.img`
    position: absolute;
    top: 3rem;
    right: 3rem;
    cursor: pointer;
  `,
  Title: styled.div`
    font-size: ${font.large};
    color: ${color.darkgray};
    font-weight: 700;
    margin-bottom: 1rem;
  `,
  Text: styled.div`
    font-size: ${font.button};
    color: ${color.gray07};
    margin-bottom: 1.5rem;
  `,
  SubTitle: styled.div`
    color: ${color.darkgray};
    font-weight: 700;
    margin-bottom: 0.5rem;
  `,
  CheckboxContainer: styled.div`
    margin-bottom: 2.5rem;
    div {
      margin-bottom: 0.5rem;
    }
  `,
  ButtonContainer: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
  `,
  Button: styled.button`
    width: 35%;
    font-size: ${font.button};
    padding: 1rem 1.5rem;
    background-color: ${color.darkgray};
    color: ${color.white};
    border-radius: 3rem;
    cursor: pointer;
    :focus {
      outline: none;
    }
    margin: 0 0.5rem;
  `,
  CloseButton: styled.button`
    width: 35%;
    font-size: ${font.button};
    padding: 1rem 1.5rem;
    color: ${color.darkgray};
    border: 1px solid ${color.darkgray};
    border-radius: 3rem;
    cursor: pointer;
    :focus {
      outline: none;
    }
    margin: 0 0.5rem;
  `,
  TextareaContainer: styled.div`
    margin-bottom: 4rem;
  `,
};

const WithdrawlModal = ({ toggle, setToggle }) => {
  const onClick = () => {
    setToggle(!toggle);
  };

  const handleSubmit = values => {
    // dispatch({ type: WITHDRWAL_REQUEST });
    const checkedValues = {
      ...values,
      reason_check: values.reason_check.filter(e => e),
    };
    console.log(checkedValues);
    // window.location.pathname = '/';
  };

  return (
    <>
      {toggle && (
        <>
          <S.Background onClick={onClick} />
          <S.Container>
            <S.CloseIcon src={icon_close} alt="close icon" onClick={onClick} />
            <S.Title>정말 프로진로고민러를 떠나실건가요?</S.Title>
            <S.Text style={{ marginBottom: '5rem' }}>
              계정을 삭제하시면 수강 중인 클래스, 유형 테스트 등 모든 활동
              정보가 삭제됩니다.
            </S.Text>
            <S.SubTitle>
              탈퇴하는 이유를 알려주세요. (복수 선택 가능)
            </S.SubTitle>
            <S.Text>서비스 개선에 참고하겠습니다.</S.Text>
            <Formik
              initialValues={{
                reason_check: [],
                reason_text: '',
              }}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <S.CheckboxContainer>
                    <Field
                      component={Checkbox}
                      name="reason_check"
                      id="reason_check1"
                      label="원하는 컨텐츠가 아닙니다."
                      checked={values.reason_check.includes('reason_check1')}
                      onChange={event => {
                        const value = event.target.checked
                          ? 'reason_check1'
                          : null;
                        setFieldValue('reason_check.0', value);
                      }}
                      style={{ marginBottom: '0.5rem' }}
                    />
                    <Field
                      component={Checkbox}
                      name="reason_check"
                      id="reason_check2"
                      label="사용하기가 너무 어렵습니다."
                      checked={values.reason_check.includes('reason_check2')}
                      onChange={event => {
                        const value = event.target.checked
                          ? 'reason_check2'
                          : null;
                        setFieldValue('reason_check.1', value);
                      }}
                      style={{ marginBottom: '0.5rem' }}
                    />
                    <Field
                      component={Checkbox}
                      name="reason_check"
                      id="reason_check3"
                      label="가격이 너무 비쌉니다."
                      checked={values.reason_check.includes('reason_check3')}
                      onChange={event => {
                        const value = event.target.checked
                          ? 'reason_check3'
                          : null;
                        setFieldValue('reason_check.2', value);
                      }}
                      style={{ marginBottom: '0.5rem' }}
                    />
                    <Field
                      component={Checkbox}
                      name="reason_check"
                      id="reason_check4"
                      label="고객지원이 만족스럽지 않습니다."
                      checked={values.reason_check.includes('reason_check4')}
                      onChange={event => {
                        const value = event.target.checked
                          ? 'reason_check4'
                          : null;
                        setFieldValue('reason_check.3', value);
                      }}
                      style={{ marginBottom: '0.5rem' }}
                    />
                    <Field
                      component={Checkbox}
                      name="reason_check"
                      id="reason_check5"
                      label="진로 고민이 해결되었습니다."
                      checked={values.reason_check.includes('reason_check5')}
                      onChange={event => {
                        const value = event.target.checked
                          ? 'reason_check5'
                          : null;
                        setFieldValue('reason_check.4', value);
                      }}
                      style={{ marginBottom: '0.5rem' }}
                    />
                    <Field
                      component={Checkbox}
                      name="reason_check"
                      id="reason_check6"
                      label="진로 고민이 해결되지 않습니다."
                      checked={values.reason_check.includes('reason_check6')}
                      onChange={event => {
                        const value = event.target.checked
                          ? 'reason_check6'
                          : null;
                        setFieldValue('reason_check.5', value);
                      }}
                      style={{ marginBottom: '0.5rem' }}
                    />
                  </S.CheckboxContainer>
                  <S.TextareaContainer>
                    <S.SubTitle>다른 의견이 있으시면 적어주세요.</S.SubTitle>
                    <Field
                      component={Textarea}
                      name="reason_text"
                      placeholder="탈퇴 사유를 적어주세요."
                      rows="5"
                    />
                  </S.TextareaContainer>
                  <S.ButtonContainer>
                    <S.CloseButton onClick={onClick}>취소</S.CloseButton>
                    <S.Button type="submit">탈퇴 하기</S.Button>
                  </S.ButtonContainer>
                </Form>
              )}
            </Formik>
          </S.Container>
        </>
      )}
    </>
  );
};

WithdrawlModal.propTypes = {
  toggle: PropTypes.bool.isRequired,
  setToggle: PropTypes.func.isRequired,
};

export default WithdrawlModal;
