import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, font } from 'styles/variables';

const S = {
  Textarea: styled.textarea`
    width: 100%;
    box-sizing: border-box;
    font-size: ${font.button};
    padding: 1rem 1.5rem;
    border: 1px solid ${color.gray02};
    border-radius: 1rem;
    outline: none;
    &:focus {
      border-color: ${color.gray07};
    }
  `,
};

const Textarea = ({
  field: { name, value, onChange, onBlur },
  placeholder,
  rows,
}) => {
  return (
    <S.Textarea
      name={name}
      value={value}
      rows={rows}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
    />
  );
};

Textarea.propTypes = {
  field: PropTypes.objectOf.isRequired,
  placeholder: PropTypes.string.isRequired,
  rows: PropTypes.number,
};

Textarea.defaultProps = {
  rows: '1',
};

export default Textarea;
