import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { ErrorMessage } from 'formik';

import { color, font } from 'styles/variables';

const S = {
  Container: styled.div`
    width: 100%;
    margin-top: 1.5rem;
  `,
  Label: styled.div`
    color: ${color.gray07};
    font-size: ${font.button};
    margin-bottom: 0.5rem;
  `,
  NumberFormat: styled(NumberFormat)`
    width: 100%;
    box-sizing: border-box;
    font-size: ${font.button};
    padding: 1rem 1.5rem;
    outline: none;
    border: solid 1px #ccc;
    border-radius: 3rem;
    :focus {
      border-color: ${color.gray07};
    }
  `,
  ErrorMessage: styled.div`
    font-size: ${font.small};
    color: ${color.red};
    margin-top: 0.5rem;
  `,
};

const NumericField = ({ name, label, placeholder, format, ...props }) => {
  const [value, setValue] = useState('');
  return (
    <S.Container>
      <S.Label>{label}</S.Label>
      <S.NumberFormat
        name={name}
        placeholder={placeholder}
        value={value}
        onValueChange={val => setValue(val.formattedValue)}
        format={format}
        {...props}
      />
      <ErrorMessage component={S.ErrorMessage} name={name} />
    </S.Container>
  );
};

NumericField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
};

export default NumericField;
