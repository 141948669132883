import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { color, font, mobileSize } from 'styles/variables';
import { icon_facebook, icon_instagram, icon_kakaotalk } from 'assets';

const S = {
  Footer: styled.footer`
    background-color: ${color.darkgray};
    color: ${color.white};
    padding: 100px 0;
    height: 230px;
    ${mobileSize} {
      height: fit-content;
      padding: 60px 1rem;
    }
  `,
  Container: styled.div`
    width: 100%;
    height: 100%;
    max-width: 1180px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${mobileSize} {
      flex-direction: column;
    }
  `,
  Info: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Title: styled.div`
    font-size: ${font.large};
    font-weight: 700;
  `,
  SubTitle: styled.div`
    margin-top: 0.6rem;
    ${mobileSize} {
      font-size: ${font.button};
    }
  `,
  Description: styled.div`
    font-size: ${font.small};
    color: ${color.gray05};
    font-weight: 300;
    line-height: 1.6;
    margin-top: 4rem;
    ${mobileSize} {
      margin: 3rem 0;
    }
  `,
  Terms: styled.div`
    margin-top: auto;
    font-size: ${font.small};
    margin-right: 2rem;
    a {
      text-decoration: none;
      color: ${color.white};
    }
  `,
  Menu: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    ${mobileSize} {
      align-items: flex-start;
    }
  `,
  GlobalNav: styled.nav`
    font-size: ${font.button};
    display: flex;
    flex-direction: row;
  `,
  GlobalNavItem: styled.div`
    margin-left: 4rem;
  `,
  GlobalNavTitle: styled.h3`
    margin-bottom: 0.8rem;
  `,
  GlobalNavLink: styled.div`
    font-weight: 300;
    line-height: 2;
  `,
  SocialNav: styled.nav`
    display: flex;
    flex-direction: row;
  `,
  SocialLink: styled.div`
    margin-left: 1.3rem;
    ${mobileSize} {
      margin: 2rem 1.3rem 0 0;
    }
  `,
};

const Footer = () => (
  <S.Footer>
    <S.Container>
      <S.Info>
        <S.Title>프로진로고민러</S.Title>
        <S.SubTitle>
          누구나 가장 나답게 살아갈 수 있도록 돕는 진로 PT 서비스
        </S.SubTitle>
        <S.Description>
          © 2020 ST.END LAB. All rights reserved.
          <br />
          <br />
          (주)스텐드 | 대표: 안지혜, 나호준 | 사업자등록번호: 730-86-00798 |
          통신판매업신고: 2018-서울광진-1235 | 개인정보관리책임자: 나호준
          <br />
          서울특별시 성동구 성수일로12길 20, 성동안심상가 407호
        </S.Description>
        {/* <S.Terms>
          <Link to="/terms">서비스 이용 약관 및 개인정보 처리 방침</Link>
        </S.Terms> */}
      </S.Info>
      <S.Menu>
        {/* <S.GlobalNav>
            <S.GlobalNavItem>
              <S.GlobalNavTitle>안내</S.GlobalNavTitle>
              <S.GlobalNavLink>공지사항</S.GlobalNavLink>
              <S.GlobalNavLink>자주 묻는 질문</S.GlobalNavLink>
            </S.GlobalNavItem>
            <S.GlobalNavItem>
              <S.GlobalNavTitle>스텐드랩</S.GlobalNavTitle>
              <S.GlobalNavLink>회사소개</S.GlobalNavLink>
              <S.GlobalNavLink>채용</S.GlobalNavLink>
            </S.GlobalNavItem>
            <S.GlobalNavItem>
              <S.GlobalNavTitle>문의</S.GlobalNavTitle>
              <S.GlobalNavLink>카카오톡: @progominler</S.GlobalNavLink>
              <S.GlobalNavLink>이메일: stend.company@gmail.com</S.GlobalNavLink>
            </S.GlobalNavItem>
          </S.GlobalNav> */}
        <S.SocialNav>
          <S.SocialLink>
            <a href="https://www.facebook.com/progominler">
              <img src={icon_facebook} alt="facebook icon" />
            </a>
          </S.SocialLink>
          <S.SocialLink>
            <a href="https://www.instagram.com/progominler/">
              <img src={icon_instagram} alt="instagram icon" />
            </a>
          </S.SocialLink>
          <S.SocialLink>
            <a href="https://pf.kakao.com/_DVYxlj">
              <img src={icon_kakaotalk} alt="kakaotalk icon" />
            </a>
          </S.SocialLink>
        </S.SocialNav>
      </S.Menu>
    </S.Container>
  </S.Footer>
);

export default Footer;
