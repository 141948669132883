import { DEVELOP_API_URL } from '../constants';
import http from './http';

export const fetchLogin = data =>
  http.post(`${DEVELOP_API_URL}/api/v1/auth/signin`, data);

export const fetchUser = headers =>
  http.get(`${DEVELOP_API_URL}/api/v1/users/me`, headers);

export const fetchReset = data =>
  http.post(`${DEVELOP_API_URL}/api/v1/auth/recovery`, data);

export const fetchPassword = data =>
  http.post(`${DEVELOP_API_URL}/api/v1/auth/resetPassword`, data);
