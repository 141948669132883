import styled from 'styled-components';

const Layout = styled.ul`
  width: 100%;
  max-width: 1180px;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export default Layout;
