import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, font, mobileSize } from 'styles/variables';

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: sticky;
    height: 3rem;
    background-color: ${color.white};
    top: 86px;
    z-index: 1000;
    ${mobileSize} {
      top: 60px;
    }
  `,
  Text: styled.div`
    font-size: ${font.button};
    color: ${color.gray07};
  `,
  Background: styled.div`
    height: 0.5rem;
    width: 100%;
    margin: 0 1rem;
    background-color: ${color.gray02};
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
  `,
  Progress: styled.div`
    width: ${props => `${props.completePercent}%`};
    background-color: ${color.darkgray};
    border-radius: 0.5rem;
  `,
};

const ProgressBar = ({ completePercent }) => {
  return (
    <S.Container>
      <S.Text>{completePercent}%</S.Text>
      <S.Background>
        <S.Progress completePercent={completePercent} />
      </S.Background>
      <S.Text>100%</S.Text>
    </S.Container>
  );
};

ProgressBar.propTypes = {
  completePercent: PropTypes.number,
};

ProgressBar.defaultProps = {
  completePercent: 0,
};

export default ProgressBar;
