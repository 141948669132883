import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form } from 'formik';

import TextField from 'components/TextField';
import Button from 'components/Button';
import { color, font } from 'styles/variables';

const S = {
  Container: styled.div`
    width: 100%;
    max-width: 380px;
    margin: auto;
    padding: 120px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  Title: styled.h1`
    color: ${color.darkgray};
    font-size: ${font.large};
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.5rem;
  `,
  Text: styled.div`
    font-size: ${font.small};
    color: ${color.gray05};
    margin-bottom: 1.5rem;
  `,
  Form: styled(Form)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Button: styled.button`
    margin-top: 1rem;
    width: 100%;
    font-size: ${font.button};
    padding: 1rem 1.5rem;
    background-color: ${color.darkgray};
    color: ${color.white};
    border-radius: 3rem;
    cursor: pointer;
    :focus {
      outline: none;
    }
  `,
};

const ResetPassword = ({ onSubmit, isLoading }) => {
  return (
    <S.Container>
      <S.Title>비밀번호 재설정</S.Title>
      <S.Text>비밀번호가 재설정 됩니다. 새로운 비밀번호를 입력주세요.</S.Text>
      <Formik
        initialValues={{
          password: '',
          passwordCheck: '',
        }}
        validate={values => {
          const errors = {};
          if (!values.password) {
            errors.password = '비밀번호를 입력해주세요.';
          } else if (
            !/^(?=.*[a-zA-Z])(?=.*[0-9]).{8,12}$/.test(values.password)
          ) {
            errors.password =
              '영문, 숫자를 포함한 8자리 이상의 비밀번호를 설정해주세요.';
          }
          if (values.passwordCheck !== values.password) {
            errors.passwordCheck = '비밀번호가 일치하지 않습니다.';
          } else if (!values.passwordCheck) {
            errors.passwordCheck = '비밀번호를 확인해주세요.';
          }
          return errors;
        }}
        onSubmit={onSubmit}
      >
        {(
          { errors, touched, isValidating }, // eslint-disable-line
        ) => (
          <S.Form>
            <TextField
              name="password"
              label="새 비밀번호"
              type="password"
              placeholder="**********"
            />
            <TextField
              name="passwordCheck"
              label="비밀번호 확인"
              type="password"
              placeholder="**********"
            />
            <Button
              type="submit"
              width="100%"
              fill="solid"
              text="비밀번호 재설정"
              isLoading={isLoading}
              style={{ marginTop: '1rem' }}
            />
          </S.Form>
        )}
      </Formik>
    </S.Container>
  );
};

ResetPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ResetPassword;
