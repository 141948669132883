import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form } from 'formik';

import { Button, TextField } from 'components';
import { color, font } from 'styles/variables';

const S = {
  Container: styled.div`
    width: 100%;
    max-width: 380px;
    margin: auto;
    padding: 120px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  Title: styled.h1`
    color: ${color.darkgray};
    font-size: ${font.large};
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.5rem;
  `,
  Form: styled(Form)`
    width: 100%;
  `,
  LinkContainer: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 3rem 0 1rem 0;
  `,
  Link: styled(Link)`
    font-size: ${font.small};
    color: ${color.gray05};
  `,
  Button: styled.button`
    width: 100%;
    font-size: ${font.button};
    padding: 1rem 1.5rem;
    background-color: ${color.darkgray};
    color: ${color.white};
    border-radius: 3rem;
    cursor: pointer;
    :focus {
      outline: none;
    }
  `,
};

const LoginForm = ({ onSubmit, isLoading }) => {
  return (
    <S.Container>
      <S.Title>로그인</S.Title>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validate={values => {
          const errors = {};
          if (!values.email) {
            errors.email = '메일을 입력해주세요.';
          }
          if (!values.password) {
            errors.password = '비밀번호를 입력해주세요.';
          }
          return errors;
        }}
        onSubmit={onSubmit}
      >
        {(
          { errors, touched, isValidating }, // eslint-disable-line
        ) => (
          <S.Form>
            <TextField
              name="email"
              label="이메일"
              placeholder="example@email.com"
            />
            <TextField
              name="password"
              label="비밀번호"
              type="password"
              placeholder="**********"
            />
            <S.LinkContainer>
              <S.Link to="/reset">비밀번호를 잊으셨나요?</S.Link>
              <S.Link to="/signup">회원 가입하기</S.Link>
            </S.LinkContainer>
            <Button
              type="submit"
              width="100%"
              text="로그인"
              fill="solid"
              isLoading={isLoading}
            />
          </S.Form>
        )}
      </Formik>
    </S.Container>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default LoginForm;
