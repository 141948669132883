import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { icon_arrow_left } from 'assets';
import { Button } from 'components';

const S = {
  ButtonContainer: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 4rem;
  `,
  BackButton: styled.div`
    padding: 1rem 2rem;
    border-radius: 3rem;
    border: 1px solid #121212;
    margin-right: 1.25rem;
    :focus {
      outline: none;
    }
  `,
};

const StepButtons = ({
  step,
  setStep,
  questionSet,
  onClick,
  onSubmit,
  isSubmitLoading,
  disabled,
}) => {
  return (
    <S.ButtonContainer>
      {step > 0 && (
        <S.BackButton type="button" onClick={() => setStep(step - 1)}>
          <img src={icon_arrow_left} alt="back icon" />
        </S.BackButton>
      )}
      {step < questionSet.length ? (
        <Button
          fill="solid"
          width="280px"
          text="다음으로"
          onClick={onClick}
          disabled={disabled}
        />
      ) : (
        <Button
          fill="solid"
          width="280px"
          text="결과보기"
          onClick={onSubmit}
          isLoading={isSubmitLoading}
        />
      )}
    </S.ButtonContainer>
  );
};

StepButtons.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  questionSet: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitLoading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default StepButtons;
