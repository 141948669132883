import { RELEASE_API_URL } from '../constants';
import http from './http';

export const fetchQuestions = () => http.get(`${RELEASE_API_URL}/api/v1/test`);

export const fetchAnswers = data =>
  http.post(`${RELEASE_API_URL}/api/v1/test`, data);

export const fetchResult = id =>
  http.get(`${RELEASE_API_URL}/api/v1/test/result/${id}`);
