import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, font, mobileSize } from 'styles/variables';
import { icon_check_circle } from 'assets';

const S = {
  Container: styled.div`
    padding: 4rem 0;
    border-bottom: 1px solid ${color.gray02};
    display: flex;
    flex-direction: column;
    align-items: center;
    ${mobileSize} {
      padding: 2rem 0;
    }
  `,
  Question: styled.p`
    text-align: center;
    color: ${color.gray07};
    font-size: ${font.question};
    font-weight: 700;
    margin-bottom: 2.5rem;
    ${mobileSize} {
      font-size: ${font.button};
      margin-bottom: 1.5rem;
    }
  `,
  GenderContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    ${mobileSize} {
      justify-content: space-around;
    }
  `,
  InputContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    ${mobileSize} {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  `,
  Text: styled.div`
    color: ${color.gray07};
    margin-top: 1rem;
    letter-spacing: -1px;
    ${mobileSize} {
      font-size: ${font.small};
    }
  `,
  Item: styled.label`
    position: relative;
    width: 60px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
    ${mobileSize} {
      width: 3rem;
      margin: 0;
    }
  `,
  HideRadio: styled.input`
    width: 100%;
    height: 100%;
    margin: 0;
    -webkit-appearance: none;
    box-shadow: none;
    display: none;
    &:checked + label {
      background-image: url(${icon_check_circle});
      background-size: cover;
      border: none;
    }
  `,
  Label: styled.label`
    width: 100%;
    height: 60px;
    position: relative;
    z-index: 1;
    display: inline-block;
    border-radius: 50%;
    border: 1px solid ${color.gray05};
    box-sizing: border-box;
    ${mobileSize} {
      height: 3rem;
    }
  `,
  Notice: styled.div`
    text-align: center;
    margin: 2rem 0 1rem 0;
    font-size: ${font.small};
    color: ${color.gray05};
    line-height: 1.5;
  `,
  Input: styled.input`
    width: 100%;
    max-width: 380px;
    box-sizing: border-box;
    font-size: ${font.button};
    padding: 1rem 1.5rem;
    outline: none;
    border: solid 1px #ccc;
    border-radius: 3rem;
    :focus {
      border-color: ${color.gray07};
    }
  `,
  ErrorMessage: styled.div`
    font-size: ${font.small};
    color: ${color.red};
    margin-top: 0.5rem;
  `,
};

const TestEnd = ({ setGender, setAge, email, setEmail, error, setError }) => {
  const container = useRef();
  const header = document.getElementById('header');

  const handleClick = () => {
    const questionContainer = container.current;
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: questionContainer.offsetTop - header.offsetHeight,
    });
  };

  const validateEmail = event => {
    setEmail(event.target.value);
    const emailChecker = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    if (!emailChecker.test(event.target.value)) {
      setError('올바른 이메일을 입력해주세요.');
    } else {
      setError('');
    }
  };

  return (
    <>
      <S.Container ref={container}>
        <S.Question>셩별이 어떻게 되시나요?</S.Question>
        <S.GenderContainer>
          <S.Item>
            <S.HideRadio
              type="radio"
              name="gender"
              id="female"
              value="female"
              onChange={event => setGender(event.target.value)}
              onClick={handleClick}
            />
            <S.Label htmlFor="female" />
            <S.Text>여성</S.Text>
          </S.Item>
          <S.Item>
            <S.HideRadio
              type="radio"
              name="gender"
              id="male"
              value="male"
              onChange={event => setGender(event.target.value)}
              onClick={handleClick}
            />
            <S.Label htmlFor="male" />
            <S.Text>남성</S.Text>
          </S.Item>
        </S.GenderContainer>
      </S.Container>
      <S.Container ref={container}>
        <S.Question>연령대가 어떻게 되시나요?</S.Question>
        <S.InputContainer>
          <S.Item>
            <S.HideRadio
              type="radio"
              name="age"
              id="age1"
              value="20"
              onChange={event => setAge(event.target.value)}
              onClick={handleClick}
            />
            <S.Label htmlFor="age1" />
            <S.Text>20세 이하</S.Text>
          </S.Item>
          <S.Item>
            <S.HideRadio
              type="radio"
              name="age"
              id="age2"
              value="21"
              onChange={event => setAge(event.target.value)}
              onClick={handleClick}
            />
            <S.Label htmlFor="age2" />
            <S.Text>21~24세</S.Text>
          </S.Item>
          <S.Item>
            <S.HideRadio
              type="radio"
              name="age"
              id="age3"
              value="25"
              onChange={event => setAge(event.target.value)}
              onClick={handleClick}
            />
            <S.Label htmlFor="age3" />
            <S.Text>25~29세</S.Text>
          </S.Item>
          <S.Item>
            <S.HideRadio
              type="radio"
              name="age"
              id="age4"
              value="30"
              onChange={event => setAge(event.target.value)}
              onClick={handleClick}
            />
            <S.Label htmlFor="age4" />
            <S.Text>30~34세</S.Text>
          </S.Item>
          <S.Item>
            <S.HideRadio
              type="radio"
              name="age"
              id="age5"
              value="35"
              onChange={event => setAge(event.target.value)}
              onClick={handleClick}
            />
            <S.Label htmlFor="age5" />
            <S.Text>35~39세</S.Text>
          </S.Item>
          <S.Item>
            <S.HideRadio
              type="radio"
              name="age"
              id="age6"
              value="40"
              onChange={event => setAge(event.target.value)}
              onClick={handleClick}
            />
            <S.Label htmlFor="age6" />
            <S.Text>40세 이상</S.Text>
          </S.Item>
        </S.InputContainer>
      </S.Container>
      <S.Container>
        <S.Question>이메일을 알려주시면 결과를 메일로 보내드릴게요!</S.Question>
        <S.Input
          type="email"
          value={email}
          onChange={validateEmail}
          placeholder="example@progominler.com"
        />
        {error !== '' && <S.ErrorMessage>{error}</S.ErrorMessage>}
      </S.Container>
      <S.Notice>
        성별과 연령대 정보는 테스트의 품질 향상을 위해 수집되며, 이외에 어떤
        다른 목적으로도 사용되지 않습니다.
      </S.Notice>
    </>
  );
};

TestEnd.propTypes = {
  setGender: PropTypes.func.isRequired,
  setAge: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setError: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
};

export default TestEnd;
