import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, font, mobileSize } from 'styles/variables';

const S = {
  Container: styled.div`
    width: 100%;
    max-width: 580px;
    margin-bottom: 4rem;
  `,
  ReportItem: styled.div`
    width: 100%;
    margin-bottom: 5rem;
  `,
  ReportTitle: styled.div`
    font-weight: 700;
    font-size: ${font.question};
    width: 100%;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${color.gray02};
    margin-bottom: 1.5rem;
  `,
  Keyword: styled.div`
    font-size: ${font.button};
    margin-bottom: 1rem;
    span {
      font-weight: 700;
    }
    ${mobileSize} {
      line-height: 1.5;
    }
  `,
  Text: styled.div`
    font-size: ${font.button};
    color: ${color.gray07};
    line-height: 1.6;
  `,
  Image: styled.img`
    width: 120px;
    height: 120px;
    min-width: 120px;
    min-height: 120px;
  `,
  ItemContainer: styled.div`
    display: flex;
    ${mobileSize} {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  `,
  TextContainer: styled.div`
    margin-left: 3rem;
    ${mobileSize} {
      margin: 2rem 0 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  `,
};

const Report = ({ title, text }) => {
  return (
    <S.Container>
      <S.ReportItem>
        <S.ReportTitle>{title} 유형은</S.ReportTitle>
        <S.Keyword>
          <span>마음 상태 키워드: </span> {text.typeKeyword}
        </S.Keyword>
        <S.Text>{text.typeDetail}</S.Text>
      </S.ReportItem>
      <S.ReportItem>
        <S.ReportTitle>나에게 필요한 해결책은</S.ReportTitle>
        <S.Keyword>
          <span>기대 상황 키워드: </span> {text.solutionKeyword}
        </S.Keyword>
        <S.Text>{text.solutionDetail}</S.Text>
      </S.ReportItem>
      <S.ReportItem>
        <S.ReportTitle>나의 진로 고민 모험 유형은</S.ReportTitle>
        <S.ItemContainer>
          <S.Image src={text.adventureImage} alt="result image" />
          <S.TextContainer>
            <S.Keyword>
              <span>{text.adventureKeyword}</span>
            </S.Keyword>
            <S.Text>{text.adventureDetail}</S.Text>
          </S.TextContainer>
        </S.ItemContainer>
      </S.ReportItem>
    </S.Container>
  );
};

Report.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Report;
