import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { color, font } from 'styles/variables';

const S = {
  Container: styled(Link)`
    display: inline-block;
    width: 100%;
    max-width: 246px;
    margin-bottom: 3rem;
    text-decoration: none;
    margin-right: 1.25rem;
    &:nth-child(3n) {
      margin-right: 0;
    }
  `,
  Thumnail: styled.span`
    display: inline-block;
    width: 100%;
    height: 138px;
    background-color: ${color.gray02};
    margin-bottom: 1rem;
  `,
  Title: styled.h3`
    font-weight: 700;
    color: ${color.darkgray};
    margin-bottom: 0.5rem;
  `,
  Step: styled.h4`
    font-size: ${font.small};
    color: ${color.gray05};
    margin-bottom: 1.5rem;
  `,
  ProgressBar: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  Background: styled.div`
    height: 0.5rem;
    background-color: ${color.gray02};
    width: 78%;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
  `,
  Progress: styled.div`
    width: ${props => `${props.width}%`};
    background-color: ${color.darkgray};
    border-radius: 0.5rem;
  `,
  ProgressRate: styled.div`
    font-size: ${font.small};
    color: ${color.darkgray};
    margin-left: 1rem;
  `,
};

const VideoItem = ({ thumnail, title, step, progress }) => {
  return (
    <S.Container to="/video">
      <S.Thumnail src={thumnail} alt="" />
      <S.Title>{title}</S.Title>
      <S.Step>{step}</S.Step>
      <S.ProgressBar>
        <S.Background>
          <S.Progress width={progress} />
        </S.Background>
        <S.ProgressRate>{progress} %</S.ProgressRate>
      </S.ProgressBar>
    </S.Container>
  );
};

VideoItem.propTypes = {
  thumnail: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  step: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
};

export default VideoItem;
