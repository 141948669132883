import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const S = {
  Container: styled.div`
    width: 100%;
    height: auto;
    max-height: 400px;
  `,
  Banner: styled.img`
    width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: cover;
  `,
};

const Banner = ({ banners }) => {
  return (
    <S.Container>
      <Carousel
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        showIndicators={false}
        dynamicHeight
        infiniteLoop
        autoPlay
      >
        {banners.map(item => (
          <div key={item.id}>
            <S.Banner src={item.image} alt="banner" />
          </div>
        ))}
      </Carousel>
    </S.Container>
  );
};

Banner.propTypes = {
  banners: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Banner;
