import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Chart from 'chart.js';
import { mobileSize } from 'styles/variables';

const S = {
  Canvas: styled.div`
    width: 100%;
    height: 100%;
    max-width: 480px;
    max-height: 480px;
    margin-bottom: 4rem;
    ${mobileSize} {
      margin-bottom: 2.5rem;
    }
  `,
};

const ResultChart = ({ fetchState, result }) => {
  const canvas = useRef();

  useEffect(() => {
    if (fetchState === 'success') {
      // eslint-disable-next-line
      const resultChart = new Chart(canvas.current, {
        type: 'radar',
        data: {
          labels: result.type.map(d => d.name),
          datasets: [
            {
              data: result.type.map(d => d.rate),
              backgroundColor: 'rgba(255, 86, 35, 0.2)',
              borderColor: '#000',
              pointRadius: 5,
              pointBackgroundColor: '#000',
              label: '',
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          tooltips: {
            enabled: false,
          },
          scale: {
            pointLabels: {
              fontSize: 12,
            },
            gridLines: {
              offsetGridLines: true,
            },
            ticks: {
              min: 0,
              max: 100,
              display: false,
              maxTicksLimit: 6,
            },
          },
        },
      });
    }
  }, [fetchState]);

  return (
    <S.Canvas>
      <canvas ref={canvas} width="400" height="400" />
    </S.Canvas>
  );
};

ResultChart.propTypes = {
  fetchState: PropTypes.string.isRequired,
  result: PropTypes.shape({
    type: PropTypes.array,
  }).isRequired,
};

export default ResultChart;
