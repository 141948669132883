import React, { useState, useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { chunk } from 'lodash';

import * as services from 'services';
import { Snackbar } from 'components';
import { ProgressBar, QuestionItem, TestEnd, StepButtons } from '../components';

const TestStart = ({ questions, setQuestions }) => {
  const [step, setStep] = useState(0);
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);

  const history = useHistory();
  const questionContainer = useRef();
  const header = document.getElementById('header');
  const questionSet = chunk(questions, 6);

  const progressRate = useMemo(
    () =>
      Math.floor(
        (questions.flat().filter(question => question.answer).length /
          questions.flat().length) *
          100,
      ),
    [questions],
  );

  const handleClick = () => {
    setStep(step + 1);
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: questionContainer.current.offsetTop - header.offsetHeight,
    });
  };

  const sendResult = async result => {
    try {
      setIsSubmitLoading(true);
      const response = await services.fetchAnswers(result);
      history.push(`/test/result/${response.data.response._id}`);
      setIsSubmitLoading(false);
    } catch (error) {
      console.log(error);
      setIsSubmitError(true);
      setIsSubmitLoading(false);
    }
  };

  const handleSubmit = () => {
    if (errorMessage === '') {
      const result = {
        gender,
        age,
        email,
        submissions: questions.flat(),
      };
      sendResult(result);
    }
  };

  const isDisabled = (questionArray, index) =>
    !(index === 0 || questionArray[index - 1].answer);

  const isButtonDisabled = (questionArray, stepNumber) =>
    stepNumber < questionArray.length &&
    !questionArray[stepNumber].every(question => question.answer !== undefined);

  const checkSubmission = useCallback(
    (event, index, index2) => {
      const answeredQuestions = [...questions];
      chunk(answeredQuestions, 6)[index][index2].answer = parseInt(
        event.target.value,
        10,
      );
      chunk(answeredQuestions, 6)[index][index2].question = event.target.name;
      setQuestions(answeredQuestions);
    },
    [questions],
  );

  return (
    <>
      {isSubmitError && (
        <Snackbar
          text="문제가 발생했습니다. 잠시후에 다시 시도해주세요."
          isError
        />
      )}
      <div ref={questionContainer}>
        <ProgressBar completePercent={progressRate} />
        {step < questionSet.length ? (
          questionSet[step].map((question, index) => (
            <QuestionItem
              key={question._id}
              step={step}
              question={question}
              index={index}
              checkSubmission={checkSubmission}
              disabled={isDisabled(questionSet[step], index)}
            />
          ))
        ) : (
          <TestEnd
            setGender={setGender}
            setAge={setAge}
            email={email}
            setEmail={setEmail}
            error={errorMessage}
            setError={setErrorMessage}
          />
        )}
        <StepButtons
          step={step}
          setStep={setStep}
          questionSet={questionSet}
          onClick={handleClick}
          onSubmit={handleSubmit}
          isSubmitLoading={isSubmitLoading}
          disabled={isButtonDisabled(questionSet, step)}
        />
      </div>
    </>
  );
};

TestStart.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  setQuestions: PropTypes.func.isRequired,
};

export default TestStart;
