import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import {
  TextField,
  NumericField,
  RadioButton,
  Checkbox,
  Button,
} from 'components';
import { color, font } from 'styles/variables';
import { icon_edit_white } from 'assets';
import WithdrawlModal from './WithdrawlModal';

const S = {
  Container: styled.div`
    width: 100%;
    max-width: 380px;
    margin: auto;
    padding: 120px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  Title: styled.h1`
    color: ${color.darkgray};
    font-size: ${font.large};
    font-weight: 700;
    text-align: center;
    margin-bottom: 2rem;
  `,
  ProfileImg: styled.div`
    position: relative;
    display: inline-block;
    width: 100px;
    height: 100px;
    margin: auto;
    display: flex;
    justify-content: center;
  `,
  Img: styled.img`
    width: 100%;
    height: 100%;
  `,
  EditIcon: styled.div`
    width: 28px;
    height: 28px;
    position: absolute;
    right: 0;
    bottom: 0;
  `,
  InputFile: styled.input`
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  `,
  Icon: styled.label`
    display: inline-block;
    width: 28px;
    height: 28px;
    background-color: ${color.darkgray};
    border-radius: 50%;
    appearance: none;
    background-image: url(${icon_edit_white});
    cursor: pointer;
  `,
  Form: styled(Form)`
    width: 100%;
  `,
  Link: styled(Link)`
    margin: 3rem 0 1rem 0;
    display: block;
    text-align: center;
    font-size: ${font.small};
    color: ${color.gray05};
    span {
      font-weight: 700;
      text-decoration: underline;
    }
  `,
  ItemContainer: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  RadioContainer: styled.div`
    margin-top: 1.5rem;
    width: 100%;
    margin-left: 20px;
  `,
  ButtonContainer: styled.div`
    padding: 0.625rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  Label: styled.div`
    color: ${color.gray07};
    font-size: ${font.button};
    margin-bottom: 0.5rem;
  `,
  ErrorMessage: styled.div`
    font-size: ${font.small};
    color: ${color.red};
    margin-top: 0.5rem;
  `,
  CheckboxContainer: styled.div`
    padding: 0.625rem 0;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  Checkbox: styled.div`
    width: 100%;
    &:last-child {
      margin-left: 20px;
    }
  `,
  WithdrawlButton: styled.div`
    margin: 3rem 0 1rem 0;
    text-align: center;
    font-size: ${font.small};
    color: ${color.gray05};
    cursor: pointer;
  `,
};

const EditUserProfile = ({
  profileImage,
  name,
  mobile,
  birthday,
  gender,
  marketing_email,
  marketing_sms,
  onSubmit,
  onChange,
  loading,
}) => {
  const [toggle, setToggle] = useState(false);

  const initialValues = {
    name,
    mobile,
    birthday,
    gender,
    marketing_email,
    marketing_sms,
  };

  const onClick = () => {
    setToggle(!toggle);
  };

  return (
    <S.Container>
      <S.Title>프로필 편집</S.Title>
      <Formik
        initialValues={initialValues}
        validate={values => {
          const errors = {};
          if (!values.name) {
            errors.username = '이름을 입력해주세요.';
          }
          if (!values.mobile) {
            errors.mobile = '전화번호를 입력해주세요.';
          }
          if (!values.birthday) {
            errors.birth = '생년월일을 입력해주세요.';
          }
          if (!values.gender) {
            errors.birth = '성별을 입력해주세요.';
          }
          return errors;
        }}
        onSubmit={onSubmit}
      >
        {(
          { values, errors, touched, isValidating, setFieldValue }, // eslint-disable-line
        ) => (
          <S.Form>
            <S.ProfileImg>
              <S.Img src={profileImage} alt="user profile" />
              <S.EditIcon>
                <S.InputFile
                  name="file"
                  type="file"
                  id="profileImage"
                  onChange={event => onChange(event)}
                />
                <S.Icon htmlFor="profileImage" />
              </S.EditIcon>
            </S.ProfileImg>
            <TextField name="name" label="이름" placeholder="홍길동" />
            <NumericField
              name="mobile"
              label="전화번호"
              value={values.mobile}
              placeholder="010 1234 5678"
              format="### #### ####"
              onValueChange={val => setFieldValue('mobile', val.formattedValue)}
            />
            <S.ItemContainer>
              <NumericField
                name="birthday"
                label="생년월일"
                value={values.birthday}
                placeholder="YYYY-MM-DD"
                format="####-##-##"
                onValueChange={val =>
                  setFieldValue('birthday', `${val.formattedValue}T00:00:00Z`)}
              />
              <S.RadioContainer>
                <S.Label htmlFor="gender">성별</S.Label>
                <S.ButtonContainer>
                  <Field
                    component={RadioButton}
                    name="gender"
                    id="female"
                    label="여성"
                  />
                  <Field
                    component={RadioButton}
                    name="gender"
                    id="male"
                    label="남성"
                  />
                </S.ButtonContainer>
                <ErrorMessage component={S.ErrorMessage} name="gender" />
              </S.RadioContainer>
            </S.ItemContainer>
            <S.CheckboxContainer>
              <S.Checkbox>
                <Field
                  component={Checkbox}
                  name="marketing_email"
                  id="marketing_email"
                  label="이메일 수신 동의"
                />
              </S.Checkbox>
              <S.Checkbox>
                <Field
                  component={Checkbox}
                  name="marketing_sms"
                  id="marketing_sms"
                  label="SMS 수신 동의"
                />
              </S.Checkbox>
            </S.CheckboxContainer>
            <S.Link to="/reset">비밀번호 변경하기</S.Link>
            <Button
              type="submit"
              width="100%"
              fill="solid"
              text="저장하기"
              isLoading={loading}
            />
            <S.WithdrawlButton onClick={onClick}>
              프로진로고민러를 더 이상 이용하고 싶지 않으신가요?
            </S.WithdrawlButton>
            <WithdrawlModal toggle={toggle} setToggle={setToggle} />
          </S.Form>
        )}
      </Formik>
    </S.Container>
  );
};

EditUserProfile.propTypes = {
  profileImage: PropTypes.string,
  name: PropTypes.string,
  mobile: PropTypes.string,
  birthday: PropTypes.string,
  gender: PropTypes.string,
  marketing_email: PropTypes.bool,
  marketing_sms: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default EditUserProfile;
