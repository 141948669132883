import React, { useState, useEffect, useCallback } from 'react';
import {
  Redirect,
  Route,
  Switch,
  BrowserRouter as Router,
} from 'react-router-dom';

import * as services from 'services';
import { useUserDispatch, useUserState } from 'contexts/userContext';
import {
  LOAD_USER_SUCCESS,
  LOAD_USER_REQUEST,
  LOAD_USER_FAILURE,
} from 'contexts/userActions';
import { GlobalStyle } from 'styles';
import { Header, Footer, Loading, Snackbar } from 'components';
import Home from 'features/landing/Home';
import NotFound from 'features/not-found/NotFound';
import { Login, Signup, ResetAuth, Reset } from 'features/auth/pages';
import { About } from 'features/about/pages';
import { Test, Result } from 'features/type-test/pages';
import { Video } from 'features/lecture/pages';
import { Store } from 'features/store/pages';
import { Profile, EditProfile } from 'features/user/pages';
import { Terms } from 'features/company/pages';
import ScrollToTop from 'utils/ScrollToTop';
import PrivateRoute from './PrivateRoute';
import { KAKAO_APP_KEY } from '../../constants';
// import PageTransition from 'components/PageTransition';

const App = () => {
  const dispatch = useUserDispatch();
  const { isLogin, error } = useUserState();
  const [isLoading, setIsLoading] = useState(false);
  const userToken = localStorage.getItem('token');

  const fetchUser = useCallback(async () => {
    try {
      setIsLoading(true);
      dispatch({ type: LOAD_USER_REQUEST });
      const response = await services.fetchUser({
        headers: { 'x-access-token': userToken },
      });
      dispatch({
        type: LOAD_USER_SUCCESS,
        payload: response.data.user,
      });
      setIsLoading(false);
    } catch (e) {
      dispatch({
        type: LOAD_USER_FAILURE,
        payload: { error: e.response },
      });
      setIsLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (userToken) {
      fetchUser(userToken);
    }
  }, [isLogin]);

  useEffect(() => {
    window.Kakao.init(KAKAO_APP_KEY);
  });

  return (
    <>
      <Router>
        <ScrollToTop>
          {isLoading && <Loading />}
          {error && (
            <Snackbar
              text="문제가 발생했습니다. 잠시후에 다시 시도해주세요."
              isError
            />
          )}
          <Header />
          <Switch>
            <Route exact path="/">
              <Redirect to="/test" />
            </Route>
            {/* <Route exact path="/" component={Home} />
            <Route path="/about" component={About} /> */}
            <Route exact path="/test" component={Test} />
            <Route path="/test/result/:resultId" component={Result} />
            {/* <Route path="/video" component={Video} />
            <Route path="/store" component={Store} />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route path="/terms" component={Terms} />
            <Route exact path="/reset" component={ResetAuth} />
            <Route path="/reset/:token" component={Reset} />
            <PrivateRoute exact path="/profile">
              <Profile />
            </PrivateRoute>
            <PrivateRoute path="/profile/edit">
              <EditProfile />
            </PrivateRoute> */}
            <Route component={NotFound} />
            {/* <Route path="*">
            <PageTransition />
          </Route> */}
          </Switch>
          <Footer />
        </ScrollToTop>
      </Router>
      <GlobalStyle />
    </>
  );
};

export default App;
