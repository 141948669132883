import React from 'react';
import Proptypes from 'prop-types';
import styled from 'styled-components';

import { color, font } from 'styles/variables';

const S = {
  Button: styled.button`
    position: relative;
    font-family: inherit;
    font-size: ${font.button};
    padding: 1rem 2rem;
    border-radius: 3rem;
    cursor: pointer;
    color: ${props => (props.fill === 'outline' ? '#121212' : '#fff')};
    background-color: ${props =>
      props.fill === 'outline' ? 'transparent' : '#121212'};
    border: ${props =>
      props.fill === 'outline' ? '1px solid #121212' : 'none'};
    :focus {
      outline: none;
    }
    width: ${props => `${props.width}`};
    opacity: ${props => (props.disabled ? '0.3' : '1')};
  `,
  Loader: styled.svg`
    enable-background: new 0 0 50 50;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
};

const Button = ({ width, fill, text, onClick, isLoading, ...props }) => {
  return (
    <S.Button
      type="button"
      width={width}
      fill={fill}
      onClick={onClick}
      {...props}
    >
      {isLoading ? (
        <>
          <span style={{ height: `${font.button}`, width: `${font.button}` }}>
            &nbsp;
          </span>
          <S.Loader
            version="1.1"
            id="loader-1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="30px"
            height="30px"
            viewBox="0 0 50 50"
            xmlSpace="preserve"
          >
            <path
              fill="#000"
              d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
              style={{ fill: `${color.white}` }}
            >
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="0.6s"
                repeatCount="indefinite"
              />
            </path>
          </S.Loader>
        </>
      ) : (
        <span>{text}</span>
      )}
    </S.Button>
  );
};

Button.propTypes = {
  width: Proptypes.string,
  fill: Proptypes.string.isRequired,
  text: Proptypes.string.isRequired,
  onClick: Proptypes.func,
  isLoading: Proptypes.bool,
};

Button.defaultProps = {
  width: 'fit-content',
  isLoading: false,
  onClick: () => {},
};

export default Button;
