import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { color, font, mobileSize } from 'styles/variables';

const S = {
  Container: styled(Link)`
    text-decoration: none;
    width: 100%;
    max-width: 380px;
    margin: 0 1.25rem 3.5rem 0;
    &:nth-child(3n) {
      margin-right: 0;
    }
  `,
  Image: styled.div`
    width: 380px;
    height: 380px;
    background: url(${props => props.image}) no-repeat;
    background-size: cover;
    margin-bottom: 1.5rem;
    background-color: #ededed;
  `,
  Name: styled.h3`
    color: ${color.darkgray};
    font-weight: 700;
    margin-bottom: 1rem;
  `,
  Summary: styled.div`
    font-size: ${font.button};
    color: ${color.gray05};
    line-height: 1.6;
    margin-bottom: 2rem;
  `,
  Price: styled.div`
    font-weight: 700;
    color: ${color.main};
    margin-bottom: 1rem;
  `,
  Tags: styled.div`
    display: flex;
    flex-direction: row;
  `,
  Tag: styled.div`
    display: inline-block;
    font-size: ${font.small};
    background-color: ${color.gray02};
    color: ${color.gray05};
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    margin-right: 0.75rem;
    ${mobileSize} {
      font-size: ${font.tiny};
      padding: 5px 10px;
      margin-right: 5px;
    }
  `,
};

const ProductItem = ({ product }) => {
  return (
    <S.Container to={`/store/${product.code}`}>
      <S.Image image={product.main_image} />
      <S.Name>{product.name}</S.Name>
      <S.Summary>{product.summary}</S.Summary>
      <S.Price>{product.price}원</S.Price>
      <S.Tags>
        {product.tags.map((tag, index) => (
          // eslint-disable-next-line
          <S.Tag key={`${product.code}_tag_${index}`}>{tag}</S.Tag>
        ))}
      </S.Tags>
    </S.Container>
  );
};

ProductItem.propTypes = {
  product: PropTypes.shape({
    code: PropTypes.number,
    main_image: PropTypes.string,
    name: PropTypes.string,
    summary: PropTypes.string,
    price: PropTypes.number,
    tags: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default ProductItem;
